import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useSpring, animated } from 'react-spring';

import ImageKuaaLogo from '../img/KuaaLogo';
import chroma from 'chroma-js';

const LoadingStyles = styled.div(
  (props) => `
    .loading-container {
      position: fixed; 
      z-index: 99999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${props.theme.color.background[20]};
      transition: background-color 1200ms ease;
    }
    svg {
      position: relative;
      width: ${props.theme.size.base * 10}px;
      top: -${props.theme.size.base * 2}px;
    }
    .loader-container {
      position: relative;
      width: ${props.theme.size.base * 24}px;
      height: ${props.theme.size.base * 0.25}px;
    }
    .loading-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .loader-bar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: ${props.theme.color.primary[50]};
      transition: background-color 1200ms ease, box-shadow 1200ms ease;
      box-shadow: 0 0
        ${props.theme.size.base * 0.125}px
        ${props.theme.size.base * 0.125}px
        ${chroma(props.theme.color.primary[50]).alpha(0.5)}
      };
    }
`
);

const Loading = (props) => {
  const breakpoints = useBreakpoint();
  const [containerLoading, setContainerLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);

  const containerProps = useSpring({
    opacity: loading ? 1 : 0,
    from: {
      opacity: 1,
    },
    config: { duration: 500 },
    delay: 500,
  });

  const loaderProps = useSpring({
    from: {
      right: '100%',
    },
    to: {
      right: '0',
    },
    config: { duration: 500 },
    delay: 500,
  });

  const contentProps = useSpring({
    opacity: loading2 ? 1 : 0,
    from: {
      opacity: 0,
    },
    config: { duration: 500 },
    delay: 500,
  });

  useEffect(() => {
    setLoading(true);
    setLoading2(true);
    const timer = setTimeout(() => {
      setLoading(false);
      setLoading2(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setContainerLoading(true);
    const timer = setTimeout(() => {
      setContainerLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <LoadingStyles theme={props.theme} breakpoints={breakpoints}>
      {containerLoading && (
        <animated.div class='loading-container' style={containerProps}>
          <animated.div class='loading-content' style={contentProps}>
            <ImageKuaaLogo
              colorPrimary={props.theme.color.foreground[60]}
              colorAccent={props.theme.color.primary[50]}
            ></ImageKuaaLogo>
            <div className='loader-container'>
              <animated.div
                class='loader-bar'
                style={loaderProps}
              ></animated.div>
            </div>
          </animated.div>
        </animated.div>
      )}
    </LoadingStyles>
  );
};

Loading.propTypes = {
  theme: PropTypes.object,
};

export default Loading;
