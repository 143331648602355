import { createFontSize, createLineHeight } from './utils/createFontSize';
import themeImage from '../img/record.jpg';
import chroma from 'chroma-js';

const gnerateRandomMonochromatic = () => {
  const randomHue = genereateRandomHue();

  const lightSaturation = (40 + Math.random() * 40) / 100;
  const lightLightness = (20 + Math.random() * 40) / 100;
  const lightBase = chroma.hsl(randomHue, lightSaturation, lightLightness);

  const darkSaturation = (40 + Math.random() * 40) / 100;
  const darkLightness = (40 + Math.random() * 40) / 100;
  const darkBase = chroma.hsl(randomHue, darkSaturation, darkLightness);

  const light = {
    primary: [lightBase.hex()],
    secondary: [lightBase.darken(1).hex()],
    tertiary: [lightBase.darken(2).hex()],
    accent: [lightBase.saturate(2).hex()],
    grayscale: [lightBase.darken(5).hex(), lightBase.brighten(5).hex()],
    background: [
      lightBase.set('hsl.l', 0.9).desaturate(1).hex(),
      lightBase.set('hsl.l', 1).desaturate(1).hex(),
    ],
    foreground: [
      lightBase.set('hsl.l', 0.1).desaturate(1).hex(),
      lightBase.set('hsl.l', 0.4).desaturate(1).hex(),
    ],
  };
  const dark = {
    primary: [darkBase.hex()],
    secondary: [darkBase.darken(1).hex()],
    tertiary: [darkBase.darken(2).hex()],
    accent: [darkBase.saturate(2).brighten(1).hex()],
    grayscale: [darkBase.darken(5).hex(), darkBase.brighten(5).hex()],
    background: [
      darkBase.set('hsl.l', 0.05).desaturate(1).hex(),
      darkBase.set('hsl.l', 0.3).desaturate(1).hex(),
    ],
    foreground: [
      darkBase.set('hsl.l', 0.8).desaturate(1).hex(),
      darkBase.set('hsl.l', 0.9).desaturate(1).hex(),
    ],
  };

  return {
    light: light,
    dark: dark,
  };
};

const generateRandomComplementary = () => {
  const spin = Math.floor(Math.random() * 3) * 30;
  const randomHue = genereateRandomHue();

  const lightSaturation = (40 + Math.random() * 40) / 100;
  const lightLightness = (20 + Math.random() * 40) / 100;
  const lightBase = chroma.hsl(randomHue, lightSaturation, lightLightness);

  const darkSaturation = (40 + Math.random() * 40) / 100;
  const darkLightness = (40 + Math.random() * 40) / 100;
  const darkBase = chroma.hsl(randomHue, darkSaturation, darkLightness);

  const light = {
    primary: [lightBase.hex()],
    secondary: [lightBase.darken(1).hex()],
    tertiary: [lightBase.darken(2).hex()],
    accent: [lightBase.saturate(2).hex()],
    grayscale: [lightBase.darken(5).hex(), lightBase.brighten(5).hex()],
    background: [
      lightBase.set('hsl.l', 0.9).desaturate(1).hex(),
      lightBase.set('hsl.l', 1).desaturate(1).hex(),
    ],
    foreground: [
      lightBase.set('hsl.l', 0.1).desaturate(1).hex(),
      lightBase.set('hsl.l', 0.4).desaturate(1).hex(),
    ],
  };
  const dark = {
    primary: [darkBase.hex()],
    secondary: [
      darkBase.set('hsl.h', spinHue(randomHue, spin)).darken(1).hex(),
    ],
    tertiary: [
      darkBase.set('hsl.h', spinHue(randomHue, -spin)).darken(2).hex(),
    ],
    accent: [
      darkBase
        .set('hsl.h', spinHue(randomHue, spin))
        .saturate(2)
        .brighten(1)
        .hex(),
    ],
    grayscale: [darkBase.darken(5).hex(), darkBase.brighten(5).hex()],
    background: [
      darkBase.set('hsl.l', 0.05).desaturate(1).hex(),
      darkBase.set('hsl.l', 0.3).desaturate(1).hex(),
    ],
    foreground: [
      darkBase.set('hsl.l', 0.8).desaturate(1).hex(),
      darkBase.set('hsl.l', 0.9).desaturate(1).hex(),
    ],
  };

  return {
    light: light,
    dark: dark,
  };
};

const generateTheme = () => {
  const lightBase = chroma('#ff4242');
  const lightSecondary = chroma('#2E86AB');
  const lightAccent = chroma('#F2BB05');

  const darkBase = chroma('#ff4242');
  const darkSecondary = chroma('#2E86AB');
  const darkAccent = chroma('#F5F749');

  const light = {
    primary: [lightBase.hex()],
    secondary: [lightSecondary.hex()],
    tertiary: [lightSecondary.darken(1).hex()],
    accent: [lightAccent.hex()],
    grayscale: [lightBase.darken(5).hex(), lightBase.brighten(5).hex()],
    background: [
      lightSecondary.set('hsl.l', 0.9).desaturate(0.8).hex(),
      lightSecondary.set('hsl.l', 1).desaturate(0.8).hex(),
    ],
    foreground: [
      lightSecondary.set('hsl.l', 0.1).desaturate(0.8).hex(),
      lightSecondary.set('hsl.l', 0.4).desaturate(0.8).hex(),
    ],
  };
  const dark = {
    primary: [darkBase.hex()],
    secondary: [darkSecondary.hex()],
    tertiary: [darkSecondary.darken(1).hex()],
    accent: [darkAccent.hex()],
    grayscale: [darkBase.darken(5).hex(), darkBase.brighten(5).hex()],
    background: [
      darkSecondary.set('hsl.l', 0.05).desaturate(0.8).hex(),
      darkSecondary.set('hsl.l', 0.3).desaturate(0.8).hex(),
    ],
    foreground: [
      darkSecondary.set('hsl.l', 0.8).desaturate(0.8).hex(),
      darkSecondary.set('hsl.l', 0.9).desaturate(0.8).hex(),
    ],
  };

  return {
    light: light,
    dark: dark,
  };
};

const spinHue = (hue, spin) => {
  const spinnedHue = (hue + spin) % 360;
  return spinnedHue;
};

const genereateRandomHue = () => {
  // return random hue and avoid yellow
  let randomHue = Math.floor(Math.random() * 360);
  return randomHue;
};

const primaryColor = chroma.hsl(genereateRandomHue(), 1, 0.6);
const randomColors = generateRandomComplementary();
const baseColors = generateTheme();

export const themeDefault = {
  themeName: 'Default',
  themeImage: themeImage,
  size: {
    base: 8,
    fontSize: createFontSize(),
    lineHeight: createLineHeight(),
  },
  color: baseColors.light,
};

export const themeDefaultDark = {
  ...themeDefault,
  color: baseColors.dark,
};
