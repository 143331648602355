import React from 'react';

import { PlayerProvider } from './src/context/PlayerContext';
import { ThemeProvider } from './src/context/ThemeContext';

import './static/fonts/fonts.css';

export const wrapRootElement = ({ element }) => (
  <PlayerProvider>
    <ThemeProvider>{element}</ThemeProvider>
  </PlayerProvider>
);
