import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import ImageKuaaLogo from '../img/KuaaLogo';

var tinycolor = require('tinycolor2');

const LogoStyles = styled.div(
  (props) => `
  pointer-events: auto;
  position: absolute;
  width: 100%;
  z-index: 900;
  top: 0;
  padding-top: ${props.theme.size.base * 1.5}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
  background: linear-gradient(
    0,
    ${tinycolor(props.theme.color.tertiary[50]).setAlpha(0).toString()},
    ${tinycolor(props.theme.color.background[30]).setAlpha(0.8).toString()}
    );
    border: 0;
  }
  .logo {
    width: 80px;
  }
  .tagline {
    font-weight: 500;
    white-space: nowrap;
    font-size: ${props.theme.size.fontSize[1]};
    line-height: ${props.theme.size.lineHeight[1]};
    color: ${
      props.theme.dark
        ? props.theme.color.tertiary[80]
        : props.theme.color.tertiary[30]
    };
    transition: color 1200ms ease;
    text-decoration: none;
    span {
      margin-left: ${props.theme.size.base}px;
    }
  }

`
);

const Logo = (props) => {
  const breakpoints = useBreakpoint();

  return (
    <LogoStyles theme={props.theme} breakpoints={breakpoints}>
      <Link
        to='/'
        onClick={() => {
          props.context.setMiniPlayer(true);
          props.context.setMiniNav(true);
        }}
        className='logo'
        title='Logo'
      >
        <ImageKuaaLogo
          colorPrimary={props.theme.color.foreground[80]}
          colorAccent={props.theme.color.primary[50]}
        ></ImageKuaaLogo>
      </Link>
      <div className='tagline'>
        99.9 FM <span>Salt Lake City</span>
      </div>
    </LogoStyles>
  );
};

Logo.propTypes = {
  theme: PropTypes.object,
  context: PropTypes.object,
};

export default Logo;
