import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { format, formatRelative, formatDistance } from 'date-fns';

const TrackInfoStyles = styled.div(
  (props) => `
  position: relative;
  z-index: 700;
  text-align: center;
  font-size: ${props.theme.size.fontSize[2]};
  line-height: ${props.theme.size.lineHeight[2]};
  background: ${props.theme.color.background[35]};
  padding: ${props.theme.size.base * 2}px;
  background: linear-gradient(
    30deg,
    ${props.theme.color.background[10]},
    ${props.theme.color.background[25]}
  );
  box-shadow: 0 0 64px ${props.theme.color.background[20]};
  .post-song,
  .post-artist,
  .post-release,
  .post-time {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 450ms ease;
    p {
      margin: 0;
      padding: 0;
    }
  }

  .post-song {
    color: ${props.theme.color.foreground[80]};
    transition: color 450ms ease;
  }
  .post-artist {
    font-weight: 600;
    color: ${props.theme.color.primary[50]};
    transition: color 450ms ease;
  }
  .post-release {
    color: ${props.theme.color.foreground[40]};
    transition: color 450ms ease;
  }
  .post-time {
    color: ${props.theme.color.foreground[0]};
    transition: color 450ms ease;
  }
  ${
    (props.breakpoints.xs || props.breakpoints.sm) &&
    !props.breakpoints.portrait &&
    `
    width: calc(50% - ${props.theme.size.base * 4}px);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: ${props.theme.size.base * 14}px;
    min-height: calc(100vh - ${props.theme.size.base * 33}px);
  `
  };
 
`
);

const TrackInfo = (props) => {
  const breakpoints = useBreakpoint();

  const compareDatetimes = (start, duration) => {
    if (!start || !duration) {
      return;
    }
    const todayDate = new Date(props.today);
    const startDate = new Date(start);
    const timeAgo = todayDate.getTime() - startDate.getTime();

    return timeAgo / 1000;
  };

  function createMarkup(html) {
    return { __html: html };
  }

  return (
    <TrackInfoStyles theme={props.theme} breakpoints={breakpoints}>
      <div
        className='post-song'
        dangerouslySetInnerHTML={createMarkup(props.song)}
      ></div>
      <div
        className='post-artist'
        dangerouslySetInnerHTML={createMarkup(props.artist)}
      ></div>
      <div
        className='post-release'
        dangerouslySetInnerHTML={createMarkup(props.release)}
      ></div>
    </TrackInfoStyles>
  );
};

TrackInfo.propTypes = {
  theme: PropTypes.object,
  song: PropTypes.string,
  artist: PropTypes.string,
  release: PropTypes.string,
  start: PropTypes.string,
  duration: PropTypes.string,
  today: PropTypes.string,
};

export default TrackInfo;
