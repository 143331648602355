import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import ThemeContext from '../context/ThemeContext';
import { colorMinMax } from '../theme/utils/minMax';

const setSize = (props) => {
  switch (props.size) {
    case -2:
      return `
        height: ${props.theme.size.base * 4}px;
        font-size: ${props.theme.size.fontSize[1]};
        line-height: ${props.theme.size.lineHeight[1]};
        ${!props.square && `padding: 0 ${props.theme.size.base * 2 - 2}px;`};
        ${props.square && `width: ${props.theme.size.base * 4}px;`}
      `;
    case -1:
      return `
        height: ${props.theme.size.base * 5}px;
        font-size: ${props.theme.size.fontSize[2]};
        line-height: ${props.theme.size.lineHeight[2]};
        ${!props.square && `padding: 0 ${props.theme.size.base * 3 - 2}px;`};
        ${props.square && `width: ${props.theme.size.base * 5}px;`}
      `;
    default:
      return `
        height: ${props.theme.size.base * 6}px;
        font-size: ${props.theme.size.fontSize[3]};
        line-height: ${props.theme.size.lineHeight[3]};
        ${!props.square && `padding: 0 ${props.theme.size.base * 3 - 2}px;`};
        ${props.square && `width: ${props.theme.size.base * 6}px;`}
      `;
    case 1:
      return `
        height: ${props.theme.size.base * 8}px;
        font-size: ${props.theme.size.fontSize[4]};
        line-height: ${props.theme.size.lineHeight[4]};
        ${!props.square && `padding: 0 ${props.theme.size.base * 3 - 2}px;`};
        ${props.square && `width: ${props.theme.size.base * 8}px;`}
      `;
  }
};

const setShape = (props) => {
  switch (props.shape) {
    default:
      return `
        border-radius: 0px;
        &:after {
          border-radius: 0px;
        }
      `;
    case 1:
      return `
        border-radius: ${props.theme.size.base * 0.5}px;
        :after {
          border-radius: ${props.theme.size.base * 0.5}px;
        }
      `;
    case 2:
      return `
        border-radius: ${props.theme.size.base * 1}px;
        &:after {
          border-radius: ${props.theme.size.base * 1}px;
        }
      `;
    case 3:
      return `
        border-radius: ${props.theme.size.base * 1.5}px;
        &:after {
          border-radius: ${props.theme.size.base * 1.5}px;
        }
      `;
    case 4:
      return `
        border-radius: 999px;
        &:after {
          border-radius: 999px;
        }
      `;
  }
};

const generateColor = (color, offset = 0, props) => {
  const foreground = props.theme.dark
    ? props.theme.color[color][colorMinMax(85)]
    : props.theme.color[color][colorMinMax(5)];

  const borderBase = 50 + offset;

  const backgroundActive = props.theme.dark ? 25 + offset : 90 + offset;
  const borderActive = props.theme.dark ? 55 + offset : 70 + offset;
  const borderFocus = props.theme.dark ? 75 + offset : 25 + offset;

  return `
    border-color: ${props.theme.color[color][colorMinMax(borderBase)]};
    color: ${foreground};
    transition: color 450ms ease, border-color 450ms ease;
    svg {
      width: 1.5em;
      height: 1.5em;
    }
    &:hover {
      border-color: ${props.theme.color[color][colorMinMax(borderFocus)]};
      color: ${foreground};
    }
    &:focus {
      border-color: ${props.theme.color[color][colorMinMax(borderFocus)]};
      color: ${foreground};
      box-shadow: 0 4px 16px
          ${
            props.theme.dark
              ? props.theme.color[color][colorMinMax(25)]
              : props.theme.color[color][colorMinMax(75)]
          },
        inset 0 0 16px
          ${
            props.theme.dark
              ? props.theme.color[color][colorMinMax(25)]
              : props.theme.color[color][colorMinMax(75)]
          };
    }
    ${
      props.active &&
      !props.disabled &&
      `
      & {
        background: ${props.theme.color[color][colorMinMax(backgroundActive)]};
        border-color: ${props.theme.color[color][colorMinMax(borderActive)]};
        color: ${foreground};
        box-shadow: 0 0 0 transparent,
          inset 0 0 16px
            ${props.theme.color[color][colorMinMax(backgroundActive - 10)]};
      }
      &:hover {
        border-color: ${props.theme.color[color][colorMinMax(borderFocus)]};
        color: ${foreground};
      }
      &:focus {
        border-color: ${props.theme.color[color][colorMinMax(borderFocus)]};
        color: ${foreground};
        box-shadow: 0 4px 16px
            ${
              props.theme.dark
                ? props.theme.color[color][colorMinMax(25)]
                : props.theme.color[color][colorMinMax(75)]
            },
          inset 0 0 16px
            ${props.theme.color[color][colorMinMax(backgroundActive - 10)]};
      }
    `
    }

    ${
      props.disabled &&
      `
      background: transparent;
      border-color: ${props.theme.color.foreground[0]};
      color: ${props.theme.color.foreground[0]};
      opacity: 0.3;
      cursor: unset;
      pointer-events: none;
      &,
      &:hover {
        background: transparent;
        border-color: ${props.theme.color.foreground[0]};
        color: ${props.theme.color.foreground[0]};
      }
    `
    }
  `;
};

const setColor = (props) => {
  switch (props.color) {
    default:
      return generateColor('primary', 0, props);
    case 'secondary':
      return generateColor('secondary', -5, props);
    case 'tertiary':
      return generateColor('tertiary', -10, props);
  }
};

const setBase = (props) => {
  return `
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.05em;
  cursor: pointer;
  border: 2px solid;
  white-space: nowrap;
  transition: border 450ms ease, color 450ms ease, background 450ms ease,
    box-shadow 450ms ease;
  background: transparent;
  &:focus {
    outline: none;
  }
  `;
};
const setSpinner = (props) => {
  return `
  opacity: 0.5;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: spinnerAnimation 1s linear 0s infinite normal both;
  }
  @keyframes spinnerAnimation {
    0%{
      box-shadow: 0px -2px ${props.theme.color.accent[60]};
    }
    25%{
      box-shadow: 2px 0px ${props.theme.color.accent[60]};
    }
    50%{
      box-shadow: 0px 2px ${props.theme.color.accent[60]};
    }
    75%{
      box-shadow: -2px 0px ${props.theme.color.accent[60]};
    }
    100%{
      box-shadow: 0px -2px ${props.theme.color.accent[60]};
    }
  }
  `;
};

const ButtonStyle = styled.button(
  (props) => `
  ${setBase(props)};
  ${setSize(props)};
  ${setShape(props)};
  ${setColor(props)};
  ${props.spinner && setSpinner(props)};
`
);

const ButtonLinkStyle = styled.a(
  (props) => `
  ${setBase(props)};
  ${setSize(props)};
  ${setShape(props)};
  ${setColor(props)};
  ${props.spinner && setSpinner(props)};
`
);

const ButtonOutline = ({
  href,
  target,
  rel,
  label,
  onClick,
  children,
  active,
  disabled,
  spinner,
  square,
  color,
  shape,
  size,
  type,
  className,
}) => (
  <ThemeContext.Consumer>
    {(theme) =>
      href ? (
        <ButtonLinkStyle
          href={href}
          target={target}
          rel={rel}
          aria-label={label}
          theme={theme}
          size={size}
          shape={shape}
          square={square}
          color={color}
          disabled={disabled}
          active={active}
          spinner={spinner}
          className={className}
        >
          {children}
        </ButtonLinkStyle>
      ) : (
        <ButtonStyle
          aria-label={label}
          theme={theme}
          size={size}
          shape={shape}
          square={square}
          color={color}
          onClick={!disabled ? onClick : null}
          disabled={disabled}
          active={active}
          spinner={spinner}
          type={type ? type : 'button'}
          className={className}
        >
          {children}
        </ButtonStyle>
      )
    }
  </ThemeContext.Consumer>
);

ButtonOutline.propTypes = {
  href: PropTypes.string,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
  rel: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  shape: PropTypes.number,
  size: PropTypes.number,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  spinner: PropTypes.bool,
  square: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default ButtonOutline;
