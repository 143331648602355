import * as React from "react";
const SvgVolumeMute = (props) => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={30}
      d="M428 156.696 84 355.304M318.126 84.812l-78.8 45.5h-60.057a39.209 39.209 0 0 0-39.209 39.21v172.96a39.209 39.209 0 0 0 39.209 39.21h60.055l78.8 45.5c26.14 15.092 58.814-3.772 58.814-33.956V118.768c.002-30.184-32.672-49.048-58.812-33.956Z"
    />
  </svg>
);
export default SvgVolumeMute;
