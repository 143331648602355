import React from 'react';
import styled from '@emotion/styled';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from 'gatsby';

import ThemeContext from '../context/ThemeContext';
import ButtonText from './ButtonText';
import SvgFacebook from '../icons/Facebook';
import SvgTwitter from '../icons/Twitter';
import SvgInstagram from '../icons/Instagram';
import ImageUaaLogo from '../img/UaaLogo';
import SvgJsMark2 from '../images/JsMark2';

const Social = styled.nav(
  (props) => `
  display: flex;
  Button {
    margin: 0 ${props.theme.size.base * 0.5}px;
  }
`
);

const FooterStyles = styled.footer(
  (props) => `
  padding:
    ${props.theme.size.base * 3}px ${props.theme.size.base * 3}px ${
    props.theme.size.base * 20
  }px;
  background: ${props.theme.color.background[15]};
  ${
    props.breakpoints.lg &&
    `
      border-radius: ${props.theme.size.base * 4}px 0 0 0;
  `
  };
  ${
    props.breakpoints.xl &&
    `
      border-radius: ${props.theme.size.base * 3}px 0 0 0;
  `
  };
  &::-webkit-scrollbar {
    height: 4px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    background: ${props.theme.color.secondary[40]};
    background: linear-gradient(
      30deg,
      ${props.theme.color.secondary[35]},
      ${props.theme.color.tertiary[50]}
      );
      border: 0;
  }
    
  ${
    (props.breakpoints.md || props.breakpoints.lg || props.breakpoints.xl) &&
    `
    padding: ${props.theme.size.base * 3}px;
    .menu {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
      }
      `
  };

  .copyright {
    border-top: 1px solid ${props.theme.color.secondary[20]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: ${props.theme.size.base * 2}px;
    padding-top: ${props.theme.size.base * 2}px;
    font-size: ${props.theme.size.fontSize[1]};
    line-height: ${props.theme.size.lineHeight[1]};
    white-space: nowrap;
    color: ${props.theme.color.foreground[0]};
    transition: color 450ms ease;
    .copy-sponsors {
      margin-top: ${props.theme.size.base * 3}px;
    }
    .copy-text {
      margin-top: ${props.theme.size.base * 2}px;
    }
    .js-logo {
      svg {
        width: ${props.theme.size.base * 4}px;
        height: ${props.theme.size.base * 4}px;
        transition: opacity 450ms ease;
      }
      &:hover svg {
        opacity: 0.6;
      }      
    }
    .uaa-logo {
      margin-left: ${props.theme.size.base * 2}px;
      svg {
        width: ${props.theme.size.base * 6}px;
        height: ${props.theme.size.base * 4}px;
        transition: opacity 450ms ease;
      }
      &:hover svg {
        opacity: 0.6;
      }      
    }
    ${
      (props.breakpoints.md || props.breakpoints.lg || props.breakpoints.xl) &&
      `
      flex-direction: row;
      flex-wrap: wrap;
      .copy-sponsors {
        margin-top: 0;
        margin-left: ${props.theme.size.base * 2}px;
      }
      .copy-text {
        margin-top: 0;
        margin-right: ${props.theme.size.base * 2}px;
      }
      `
    };
  }
  .menu-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .navbar-item {
      span {
        color: ${props.theme.color.accent[60]};
        font-size: ${props.theme.size.fontSize[0]};
        line-height: ${props.theme.size.lineHeight[0]};
      }
      font-size: ${props.theme.size.fontSize[1]};
      line-height: ${props.theme.size.lineHeight[1]};
      white-space: nowrap;
      color: ${
        props.theme.dark
          ? props.theme.color.foreground[60]
          : props.theme.color.foreground[20]
      };
      transition: color 450ms ease;
      text-decoration: none;
      padding: ${props.theme.size.base}px ${props.theme.size.base * 2}px;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 0.1em;
      &:hover {
        color: ${
          props.theme.dark
            ? props.theme.color.foreground[100]
            : props.theme.color.foreground[0]
        };
      }
    }
    ${
      (props.breakpoints.md || props.breakpoints.lg || props.breakpoints.xl) &&
      `
      align-items: flex-start;
    `
    };
  }
`
);

const Footer = (props) => {
  const breakpoints = useBreakpoint();

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <FooterStyles theme={theme} breakpoints={breakpoints}>
          <div className='menu'>
            <div className='menu-list'>
              <Link className='navbar-item' to='/'>
                Home
              </Link>
              <Link className='navbar-item' to='/schedule'>
                Schedule
              </Link>
              <Link className='navbar-item' to='/djs'>
                DJs
              </Link>
            </div>
            <div className='menu-list'>
            <a
                className='navbar-item'
                href='https://shop.kuaafm.org/collections/all'
                target='_blank'
                rel='noopener noreferrer'
              >
                Shop
              </a>
              <Link className='navbar-item' to='/archive'>
                Archive <span>Beta</span>
              </Link>
              <Link className='navbar-item' to='/alexa'>
                Amazon Alexa
              </Link>
            </div>
            <div className='menu-list'>
              <Link className='navbar-item' to='/about'>
                About
              </Link>
              <Link className='navbar-item' to='/donate'>
                Donate
              </Link>
              <Link className='navbar-item' to='/underwriting'>
                Underwriting
              </Link>
            </div>
            <div className='menu-list'>
              <Link className='navbar-item' to='/blog'>
                Blog
              </Link>
              <Link className='navbar-item' to='/contact'>
                Contact
              </Link>
              <Link className='navbar-item' to='/settings'>
                Settings <span>Beta</span>
              </Link>
            </div>
          </div>
          <div className='copyright'>
            <Social theme={theme}>
              <ButtonText
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.facebook.com/kuaaradio'
                square={true}
                size={breakpoints.xs || breakpoints.sm ? 0 : -1}
                shape={4}
                color='tertiary'
                label='Visit us on Facebook'
              >
                <SvgFacebook></SvgFacebook>
              </ButtonText>
              <ButtonText
                target='_blank'
                rel='noopener noreferrer'
                href='https://twitter.com/kuaaradio'
                square={true}
                size={breakpoints.xs || breakpoints.sm ? 0 : -1}
                shape={4}
                color='tertiary'
                label='Visit us on Twitter'
              >
                <SvgTwitter></SvgTwitter>
              </ButtonText>
              <ButtonText
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.instagram.com/kuaaradio/'
                square={true}
                size={breakpoints.xs || breakpoints.sm ? 0 : -1}
                shape={4}
                color='tertiary'
                label='Visit us on Instagram'
              >
                <SvgInstagram></SvgInstagram>
              </ButtonText>
            </Social>
            <div className='copy-text'>
              &copy; {new Date().getFullYear()} &nbsp; KUAA
            </div>
            <div className='copy-sponsors'>
              <a
                className='js-logo'
                href='https://www.jessestewart.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <SvgJsMark2 colorPrimary={theme.color.primary[50]}></SvgJsMark2>
              </a>
              <a
                className='uaa-logo'
                href='https://utaharts.org/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <ImageUaaLogo
                  colorPrimary={theme.color.primary[50]}
                ></ImageUaaLogo>
                <span className='visually-hidden'>Utah Arts Alliance</span>
              </a>
            </div>
          </div>
        </FooterStyles>
      )}
    </ThemeContext.Consumer>
  );
};

export default Footer;
