export const createFontSize = (value = 16, count = 16, round = 2) => {
  const scale = [];
  for (let i = 0; i < count; i++) {
    const size = scaleFont(i, value, round);
    //const sizePx = size + 'px';
    const sizeRem = (1 / 16) * size + 'rem';
    scale.push(sizeRem);
  }

  return scale;
};

export const scaleFont = (
  size = 0,
  baseValue = 16,
  round = 8,
  increments = defaultFontIncrements
) => {
  const scaledValue = baseValue * increments[size];
  const roundedValue = round * Math.round(scaledValue / round);
  return roundedValue;
};

export const createLineHeight = (value = 16, count = 16, round = 8) => {
  const scale = [];
  for (let i = 0; i < count; i++) {
    const height = scaleLineHeight(i, value, round);
    //const heightPx = height + 'px';
    const heightRem = (1 / 16) * height + 'rem';
    scale.push(heightRem);
  }

  return scale;
};

export const scaleLineHeight = (
  size = 0,
  baseValue = 16,
  round = 8,
  increments = defaultFontIncrements
) => {
  const scaledValue = baseValue * 1.5 * increments[size];
  const roundedValue = round * Math.round(scaledValue / round);
  return roundedValue;
};

export const defaultFontIncrements = [
  0.625,
  0.75,
  0.875,
  1,
  1.125,
  1.25,
  1.375,
  1.5,
  1.75,
  2,
  2.5,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
];
