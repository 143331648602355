var chroma = require('chroma-js');

export const createColorScale = (color) => {
  if (!color) {
    return false;
  }
  const colorCount = 21;
  const colorRange = [0, 100];
  const increment = (colorRange[1] - colorRange[0]) / (colorCount - 1);

  let scale = {};
  let colorvar = '';

  if (color.length > 1) {
    colorvar = chroma.scale(color).classes(colorCount).mode('lab');
  } else {
    const colorDark = chroma(color[0]).set('hsl.l', 0).hex();
    const colorLight = chroma(color[0]).set('hsl.l', 1).hex();
    colorvar = chroma
      .scale([colorDark, color[0], colorLight])
      .classes(colorCount);
  }

  for (let i = 0; i < colorCount; i++) {
    const colorName = colorRange[0] + increment * i;
    scale[colorName] = colorvar(colorName * 0.01).hex();
  }

  return scale;
};
