export const colorMinMax = (value) => {
  if (!value) {
    return 0;
  }
  let newValue = value;
  if (value < 0) {
    newValue = 0;
  }
  if (value > 100) {
    newValue = 100;
  }
  if (value % 5 !== 0) {
    newValue = value - (value % 5);
  }
  return newValue;
};
