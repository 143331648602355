import * as React from "react";
const SvgBroadcast = (props) => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    viewBox="0 0 512 512"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="none" stroke="currentColor" strokeWidth={30}>
      <path d="m130 450 126-318.3L382 450M169.4 350.5h173.2M338.4 194.6c28.9-28.9 28.9-75.9 0-104.8M173.6 194.6c-28.9-28.9-28.9-75.9 0-104.8M382.1 222.3c44.3-44.3 44.3-116.1 0-160.4M129.9 222.3c-44.3-44.3-44.3-116.1 0-160.4" />
    </g>
  </svg>
);
export default SvgBroadcast;
