import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import ButtonOutline from './../ButtonOutline';

import { navigate } from 'gatsby-link';
import SvgClose from '../../icons/Close';
import ButtonText from '../ButtonText';

const PlaylistStyles = styled.div(
  (props) => `
  padding: 0 0 
    ${props.theme.size.base * 8}px;
    background: ${props.theme.color.background[55]};
  .playlist-header {
    padding: ${props.theme.size.base * 1}px;
    font-size: ${props.theme.size.fontSize[1]};
    line-height: ${props.theme.size.lineHeight[2]};
    color: ${props.theme.color.primary[70]};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${props.theme.color.primary[20]};
  }
  .playlist-item {
    padding: ${props.theme.size.base * 1}px;
    margin-bottom: ${props.theme.size.base * 3}px;
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    display: flex;
    img {
      margin-right: ${props.theme.size.base * 2}px;
      width: ${props.theme.size.base * 8}px;
      height: ${props.theme.size.base * 8}px;
      border-radius: ${props.theme.size.base * 0.5}px;
    }
    .post-image-placeholder {
      position: relative;
      margin-right: ${props.theme.size.base * 2}px;
      width: ${props.theme.size.base * 8}px;
      height: ${props.theme.size.base * 8}px;
      border-radius: ${props.theme.size.base * 0.5}px;
      font-size: ${props.theme.size.base * 1}px;
      line-height: ${props.theme.size.base * 1.5}px;
      padding: ${props.theme.size.base * 0.5}px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      background: ${props.theme.color.tertiary[20]};
      border: 1px solid ${props.theme.color.tertiary[40]};
      color: ${props.theme.color.tertiary[60]};
      transition: color 450ms ease, background 450ms ease, border 450ms ease;    
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      &:after {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: ${props.theme.size.base * 7}px;
        height: ${props.theme.size.base * 7}px;
        border-radius: 50%;
        border: 1px solid ${props.theme.color.tertiary[30]};
      }
      &:before {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: ${props.theme.size.base * 3}px;
        height: ${props.theme.size.base * 3}px;
        border-radius: 50%;
        border: 1px solid ${props.theme.color.tertiary[25]};
      }
    }
  }

  .post-song,
  .post-artist,
  .post-release {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .post-song {
    color: ${props.theme.color.foreground[80]};
    transition: color 450ms ease;
  }
  .post-artist {
    font-weight: 600;
    color: ${props.theme.color.primary[50]};
    transition: color 450ms ease;
  }
  .post-release {
    color: ${props.theme.color.foreground[40]};
    transition: color 450ms ease;
  }
  .post-time {
    color: ${props.theme.color.foreground[0]};
    transition: color 450ms ease;
  }
  .post-info {
    width: calc(100% - ${props.theme.size.base * 12}px);
  }
  .footer {
    display: flex;
    justify-content: center;
  }
`
);

const PlayerPlaylist = (props) => {
  const compareDatetimes = (start, duration) => {
    if (!start || !duration) {
      return;
    }
    const todayDate = new Date(props.today);
    const startDate = new Date(start);
    const timeAgo = todayDate.getTime() - startDate.getTime();

    return timeAgo / 1000;
  };

  return (
    <PlaylistStyles theme={props.theme}>
      <div className='playlist-header'>
        Playlist
        <ButtonText
          size={-2}
          square={true}
          shape={4}
          onClick={() => {
            props.context.setShowPlaylist(false);
          }}
        >
          <SvgClose />
        </ButtonText>
      </div>
      {props.posts &&
        props.posts.map((post, index) => (
          <div key={index} className='playlist-item'>
            {post.image ? (
              <img
                src={post.image}
                alt={post.artist + ' ' + post.release + ' album artwork'}
              />
            ) : (
              <div className='post-image-placeholder'>
                {post.release ? post.release : post.artist}
              </div>
            )}
            <div className='post-info'>
              <div className='post-song'>{post.song}</div>
              <div className='post-artist'>{post.artist}</div>
              <div className='post-release'>{post.release}</div>
              {compareDatetimes(post.start, post.duration) > post.duration ? (
                <Moment className='post-time' fromNow>
                  {post.start}
                </Moment>
              ) : (
                <div className='post-time'>Playing Now</div>
              )}
            </div>
          </div>
        ))}
      <div className='footer'>
        <ButtonOutline
          onClick={() => {
            navigate(`/playlist`);
          }}
          size={-1}
          shape={4}
          color='tertiary'
        >
          Playlist
        </ButtonOutline>
      </div>
    </PlaylistStyles>
  );
};

PlayerPlaylist.propTypes = {
  theme: PropTypes.object,
  posts: PropTypes.array,
  today: PropTypes.string,
};

export default PlayerPlaylist;
