import React from 'react';
import styled from '@emotion/styled';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import ButtonOutline from '../ButtonOutline';
import SvgClose from '../../icons/Close';
import SvgVolume from '../../icons/Volume';
import SvgVolumeMute from '../../icons/VolumeMute';
import ButtonText from '../ButtonText';

const VolumeContainer = styled.div(
  (props) => `
    position: absolute;
    left: ${props.theme.size.base * 2}px; top: ${props.theme.size.base * 2}px;
    width: calc(100% - ${props.theme.size.base * 4}px);
    z-index: 1200;
    background: ${props.theme.color.background[30]};
    padding: ${props.theme.size.base * 2}px;
    gap: ${props.theme.size.base * 2}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-radius: ${props.theme.size.base * 6}px;
    &.mini {
      left: ${props.theme.size.base * 1.5}px; top: ${props.theme.size.base * 1.5}px;
      width: calc(100% - ${props.theme.size.base * 3}px);
      border-radius: ${props.theme.size.base * 4}px;
      padding: ${props.theme.size.base * 1}px;
    }
    .icon {
      font-size: ${props.theme.size.fontSize[6]};
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .rc-slider {
      position: relative;
      height: 14px;
      padding: 5px 0;
      width: 100%;
      border-radius: 6px;
      touch-action: none;
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-slider * {
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-slider-rail {
      position: absolute;
      width: 100%;
      background-color: ${props.theme.color.background[90]};
      height: 4px;
      border-radius: 6px;
    }
    .rc-slider-track {
      position: absolute;
      left: 0;
      height: 4px;
      border-radius: 6px;
      background-color: ${props.theme.color.tertiary[50]};
    }
    .rc-slider-handle {
      position: absolute;
      width: 14px;
      height: 14px;
      cursor: pointer;
      cursor: -webkit-grab;
      margin-top: -5px;
      cursor: grab;
      border-radius: 50%;
      border: solid 2px ${props.theme.color.primary[50]};
      background-color: ${props.theme.color.primary[50]};
      touch-action: pan-x;
    }
    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
      border-color: #57c5f7;
      box-shadow: 0 0 0 5px ${props.theme.color.primary[50]};
    }
    .rc-slider-handle:focus {
      outline: none;
    }
    .rc-slider-handle-click-focused:focus {
      border-color: ${props.theme.color.primary[50]};
      box-shadow: unset;
    }
    .rc-slider-handle:hover {
      border-color: ${props.theme.color.primary[50]};
    }
    .rc-slider-handle:active {
      border-color: ${props.theme.color.primary[50]};
      box-shadow: 0 0 5px ${props.theme.color.primary[50]};
      cursor: -webkit-grabbing;
      cursor: grabbing;
    }
    .rc-slider-mark {
      position: absolute;
      top: 18px;
      left: 0;
      width: 100%;
      font-size: 12px;
    }
    .rc-slider-mark-text {
      position: absolute;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      color: #999;
    }
    .rc-slider-mark-text-active {
      color: #666;
    }
    .rc-slider-step {
      position: absolute;
      width: 100%;
      height: 4px;
      background: transparent;
    }
    .rc-slider-dot {
      position: absolute;
      bottom: -2px;
      margin-left: -4px;
      width: 8px;
      height: 8px;
      border: 2px solid ${props.theme.color.primary[50]};
      background-color: #fff;
      cursor: pointer;
      border-radius: 50%;
      vertical-align: middle;
    }
    .rc-slider-dot-active {
      border-color: ${props.theme.color.primary[50]};
    }
    .rc-slider-dot-reverse {
      margin-right: -4px;
    }
    .rc-slider-disabled {
      background: transparent;
      opacity: 0.5;
    }
    .rc-slider-vertical {
      width: 14px;
      height: 100%;
      padding: 0 5px;
    }
    .rc-slider-vertical .rc-slider-rail {
      height: 100%;
      width: 4px;
    }
    .rc-slider-vertical .rc-slider-track {
      left: 5px;
      bottom: 0;
      width: 4px;
    }
    .rc-slider-vertical .rc-slider-handle {
      margin-left: -5px;
      touch-action: pan-y;
    }
    .rc-slider-vertical .rc-slider-mark {
      top: 0;
      left: 18px;
      height: 100%;
    }
    .rc-slider-vertical .rc-slider-step {
      height: 100%;
      width: 4px;
    }
    .rc-slider-vertical .rc-slider-dot {
      left: 2px;
      margin-bottom: -4px;
    }
    .rc-slider-vertical .rc-slider-dot:first-child {
      margin-bottom: -4px;
    }
    .rc-slider-vertical .rc-slider-dot:last-child {
      margin-bottom: -4px;
    }
    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
      animation-duration: 0.3s;
      animation-fill-mode: both;
      display: block !important;
      animation-play-state: paused;
    }
    .rc-slider-tooltip-zoom-down-leave {
      animation-duration: 0.3s;
      animation-fill-mode: both;
      display: block !important;
      animation-play-state: paused;
    }
    .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
    .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
      animation-name: rcSliderTooltipZoomDownIn;
      animation-play-state: running;
    }
    .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
      animation-name: rcSliderTooltipZoomDownOut;
      animation-play-state: running;
    }
    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
      transform: scale(0, 0);
      animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }
    .rc-slider-tooltip-zoom-down-leave {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
    @keyframes rcSliderTooltipZoomDownIn {
      0% {
        opacity: 0;
        transform-origin: 50% 100%;
        transform: scale(0, 0);
      }
      100% {
        transform-origin: 50% 100%;
        transform: scale(1, 1);
      }
    }
    @keyframes rcSliderTooltipZoomDownOut {
      0% {
        transform-origin: 50% 100%;
        transform: scale(1, 1);
      }
      100% {
        opacity: 0;
        transform-origin: 50% 100%;
        transform: scale(0, 0);
      }
    }
    .rc-slider-tooltip {
      position: absolute;
      left: -9999px;
      top: -9999px;
      visibility: visible;
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-slider-tooltip * {
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-slider-tooltip-hidden {
      display: none;
    }
    .rc-slider-tooltip-placement-top {
      padding: 4px 0 8px 0;
    }
    .rc-slider-tooltip-inner {
      padding: 6px 2px;
      min-width: 24px;
      height: 24px;
      font-size: 12px;
      line-height: 1;
      color: #fff;
      text-align: center;
      text-decoration: none;
      background-color: #6c6c6c;
      border-radius: 6px;
      box-shadow: 0 0 4px #d9d9d9;
    }
    .rc-slider-tooltip-arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
    }
    .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
      bottom: 4px;
      left: 50%;
      margin-left: -4px;
      border-width: 4px 4px 0;
      border-top-color: #6c6c6c;
    }
    
  `
);

const Volume = (props) => {
  return <VolumeContainer theme={props.theme} className={props.mini ? 'mini' : ''}>
    <ButtonText
      onClick={() => props.setMute(!props.mute)}
      active={props.mute}
      square={true}
      shape={4}
      size={-2}
      color='secondary'
      label={props.mute ? 'Unmute' : 'Mute'}
    >
      {props.mute ? <SvgVolumeMute></SvgVolumeMute> : <SvgVolume></SvgVolume>}
    </ButtonText>
    <Slider value={props.volume} style={{width:'100%'}}
      onChange={(value) => props.setVolume(value)}
      min={0.0}
      max={1.0}
      step={0.01}
      disabled={props.mute}
    />
    <ButtonOutline
      square={true}
      shape={4}
      size={-2}
      color='secondary'
      active={true}
      onClick={() => props.setShowVolume(false)}
      label='Close Volume Control'
    >
      <SvgClose></SvgClose>
    </ButtonOutline>
  </VolumeContainer>;
};

export default Volume;
