import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const ProgressStyles = styled.div(
  (props) => `
  width: 100%;
  margin-top: ${props.theme.size.base * 2}px;

  .progress-bar {
    position: relative;
    height: ${props.theme.size.base * 4}px;
    background: ${props.theme.color.tertiary[10]};
    border-radius: ${props.theme.size.base * 3}px;
    overflow: hidden;
    .progress-played {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background: ${props.theme.color.tertiary[40]};
    }
    .progress-loaded {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background: ${props.theme.color.tertiary[20]};
      opacity: 0.5;
    }
    .progress-text {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .progress-title {
      padding: 0 0 0 ${props.theme.size.base * 2}px;
      position: relative;
      color: ${props.theme.color.accent[65]};
      font-size: ${props.theme.size.fontSize[1]};
      line-height: ${props.theme.size.base * 4}px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .progress-time {
      padding: 0 ${props.theme.size.base * 2}px;
      font-size: ${props.theme.size.fontSize[1]};
      line-height: ${props.theme.size.base * 4}px;
      letter-spacing: 0.08em;
      color: ${props.theme.color.accent[80]};
      span {
        margin: 0 ${props.theme.size.base * 0.5}px;
        color: ${props.theme.color.accent[80]};
        opacity: 0.5;
      }
    }
  }
`
);

const Progress = (props) => {
  const breakpoints = useBreakpoint();

  useEffect(() => { });

  const formatTime = (time) => {
    // convert seconds to minutes:seconds
    let minutes = Math.floor(time / 60);
    let seconds = parseFloat(time - minutes * 60).toFixed(0);
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${minutes}:${seconds}`;
  };

  return (
    <ProgressStyles theme={props.theme} breakpoints={breakpoints}>
      <div className='progress-bar'>
        <div
          className='progress-loaded'
          style={{ width: `${props.progress.loaded * 100}%` }}
        />
        <div
          className='progress-played'
          style={{ width: `${props.progress.played * 100}%` }}
        />
        <div className='progress-text'>
          <div className='progress-title'>{props.title}</div>
          { // if props.progress.playedSeconds and props.duration are numbers
            typeof props.progress.playedSeconds === 'number' &&
            typeof props.duration === 'number' && (
              <div className='progress-time'>
                {formatTime(props.progress.playedSeconds)}
                <span>/</span>
                {formatTime(props.duration)}
              </div>
            )}
        </div>
      </div>
    </ProgressStyles>
  );
};

Progress.propTypes = {
  theme: PropTypes.object,
};

export default Progress;
