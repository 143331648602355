import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import ButtonOutline from '../ButtonOutline';
import ButtonText from '../ButtonText';
import SvgPlay from '../../icons/Play';
import SvgClose from '../../icons/Close';
import SvgPause from '../../icons/Pause';
import SvgBroadcast from '../../icons/Broadcast';

const TracklistStyles = styled.div(
  (props) => `
    width: 100%;
    background: ${props.theme.color.background[20]};
    z-index: 900;
    .player-tracklist-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${props.theme.size.base * 1}px;
      font-size: ${props.theme.size.fontSize[1]};
      line-height: ${props.theme.size.lineHeight[2]};
      &:hover {
        cursor: pointer;
        background: ${props.theme.color.secondary[15]};
      }
      &.active {
        background: ${props.theme.color.primary[15]};
      }
    }
    .player-tracklist-actions {
      display: flex;
      align-items: center;
      gap: ${props.theme.size.base * 1}px;
    }
`
);

const Tracklist = (props) => {
  const breakpoints = useBreakpoint();

  useEffect(() => {});

  return (
    <TracklistStyles theme={props.theme} breakpoints={breakpoints}>
      {props.context.tracklist &&
        props.context.tracklist.length > 1 &&
        props.context.tracklist.map((track, index) => {
          return (
            <div
              className={`player-tracklist-item ${
                track.active ? 'active' : ''
              }`}
              key={index}
              onClick={() => props.context.setTrack(track)}
            >
              <div className='player-tracklist-actions'>
                {track.active && props.context.playing ? (
                  <ButtonOutline
                    active={true}
                    square={true}
                    shape={4}
                    size={-2}
                    onClick={() => props.context.setPlaying(false)}
                    label='Pause Audio'
                  >
                    <SvgPause />
                  </ButtonOutline>
                ) : (
                  <ButtonOutline
                    square={true}
                    shape={4}
                    size={-2}
                    onClick={(e) => (
                      e.stopPropagation(),
                      props.context.setTrack(track),
                      props.context.setPlaying(true)
                    )}
                    label={`Play ${track.name} Episode`}
                  >
                    <SvgPlay />
                  </ButtonOutline>
                )}
                <div>
                  {track.name === props.context.tracklist[0].name && (
                    <SvgBroadcast
                      style={{ marginRight: props.theme.size.base * 1 }}
                    />
                  )}
                  {track.name}
                </div>
              </div>
              {track.name === props.context.tracklist[0].name ? (
                <ButtonOutline
                  color='accent'
                  shape={4}
                  size={-2}
                  onClick={(e) => (
                    e.stopPropagation(), props.context.clearTracklist(track)
                  )}
                >
                  Clear Queue
                </ButtonOutline>
              ) : (
                <ButtonText
                  square={true}
                  shape={4}
                  size={-2}
                  onClick={(e) => (
                    e.stopPropagation(),
                    props.context.removeFromTracklist(track)
                  )}
                  label={`Remove ${track.name} From Queue`}
                >
                  <SvgClose />
                </ButtonText>
              )}
            </div>
          );
        })}
    </TracklistStyles>
  );
};

Tracklist.propTypes = {
  theme: PropTypes.object,
};

export default Tracklist;
