import * as React from "react";
const SvgTwitter = (props) => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={30}
      d="M206.7 398.285h-16.585a18.252 18.252 0 0 1-2.916-.234c-2.209-.1-4.319-.28-6.605-.482-4.54-.4-9.254-1-14.008-1.779a191.214 191.214 0 0 1-32.74-8.419 193.381 193.381 0 0 1-38.485-18.664 32.468 32.468 0 0 1-1.2-.8l-.116-.08a18.341 18.341 0 0 1 11.923-33.45 117.278 117.278 0 0 0 27.654-.566 104.437 104.437 0 0 0 15.887-3.578c-15.055-10.839-25.461-26.126-29.6-41.106a18.327 18.327 0 0 1 .257-10.607c-15.854-16.35-23.741-38.258-22.359-57.648a18.333 18.333 0 0 1 6.027-12.328 80.144 80.144 0 0 1 4.975-70.034 18.334 18.334 0 0 1 30.068-2.474 158.537 158.537 0 0 0 23.294 23.206 156.616 156.616 0 0 0 27.291 17.69A158.313 158.313 0 0 0 219.94 188.6c3.028.819 7.253 1.688 11.592 2.463a79.92 79.92 0 0 1 53.674-68.746 84.583 84.583 0 0 1 22.063-4.243q.631-.045 1.264-.044h4.118a20.406 20.406 0 0 1 4.619.3 78 78 0 0 1 13.39 2.217 79.151 79.151 0 0 1 30.266 15.174c2.424-.7 4.755-1.464 7.024-2.3a105.353 105.353 0 0 0 16.239-7.615 19.052 19.052 0 0 1 15.788-2.051 18.334 18.334 0 0 1 11.986 23.4q-.384 1.131-.794 2.248a18.71 18.71 0 0 1 6.346 27.109l-1.466 2.073a144.014 144.014 0 0 1-20.1 22.881q-2.5 2.3-5.08 4.451c-.039 4.5-.249 9.123-.637 14.054a184.397 184.397 0 0 1-2.186 17.268 202.929 202.929 0 0 1-34.994 83.166 191.5 191.5 0 0 1-25.266 28.627 183.223 183.223 0 0 1-52.1 33.9 186.811 186.811 0 0 1-35.72 11.213 183.946 183.946 0 0 1-21.312 3.206q-2.952.261-5.9.516l-1.922.166a20.317 20.317 0 0 1-1.467.072 18.24 18.24 0 0 1-2.665.18Z"
    />
  </svg>
);
export default SvgTwitter;
