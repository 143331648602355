import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import ThemeContext from '../context/ThemeContext';
import { colorMinMax } from '../theme/utils/minMax';

var tinycolor = require('tinycolor2');

const generateColor = (color, offset = 0, props) => {
  const foregroundDark = props.theme.color[color][5];
  const foregroundLight = props.theme.color[color][85];
  const backgroundBase = 40; // min: 25, max: 75

  return `
    background: ${
      props.theme.color[color][colorMinMax(backgroundBase + offset)]
    };
    color: ${tinycolor
      .mostReadable(
        props.theme.color[color][colorMinMax(backgroundBase + offset)],
        [foregroundDark, foregroundLight]
      )
      .toHexString()};
    };
    transition: color 450ms ease, background 450ms ease;
    `;
};

const setColor = (props) => {
  switch (props.color) {
    default:
      return generateColor('primary', 0, props);
    case 'secondary':
      return generateColor('secondary', -5, props);
    case 'tertiary':
      return generateColor('tertiary', -10, props);
  }
};
const setHoverColor = (props) => {
  switch (props.color) {
    default:
      return generateColor('primary', 5, props);
    case 'secondary':
      return generateColor('secondary', 0, props);
    case 'tertiary':
      return generateColor('tertiary', 0, props);
  }
};

const BadgeStyle = styled.div(
  (props) => `
  text-shadow: none;
  box-shadow: 0
    ${props.theme.size.base * 0.25}px
    ${props.theme.size.base * 1.5}px
    ${props.theme.dark ? `rgba(0,0,0,0.2)` : `rgba(0,0,0,0.1)`};
  font-weight: 600;
  letter-spacing: 0.03em;
  font-size: ${props.theme.size.fontSize[1]};
  line-height: ${props.theme.size.lineHeight[1]};
  padding:
    ${props.theme.size.base * 1}px
    ${props.theme.size.base * 1.5}px
    ${props.theme.size.base * 1}px;
  border-radius: ${props.theme.size.base * 2}px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  ${setColor(props)};
  ${
    props.onClick
      ? `
    cursor: pointer;
    &:hover {
      ${setHoverColor(props)};
    }
  `
      : ``
  };
  ;
`
);

const Badge = ({ children, color, className, onClick }) => (
  <ThemeContext.Consumer>
    {(theme) => (
      <BadgeStyle
        theme={theme}
        color={color}
        className={className}
        onClick={onClick}
      >
        {children}
      </BadgeStyle>
    )}
  </ThemeContext.Consumer>
);

Badge.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Badge;
