import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styled from '@emotion/styled';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { format, formatRelative, formatDistance } from 'date-fns';
import placeHolderImage from '../../img/record-texture.png';

import PlayerContext from '../../context/PlayerContext';
import ThemeContext from '../../context/ThemeContext';

import TrackArt from './TrackArt';
import TrackBackground from './TrackBackground';
import MiniPlayer from './MiniPlayer';
import TrackInfo from './TrackInfo';
import Tracklist from './Tracklist';
import Controls from './Controls';
import PlayerPlaylist from './PlayerPlaylist';
import Logo from '../Logo';
import { generateTheme } from '../../theme/utils/generateTheme';

const PlayerWrapper = styled.div(
  (props) => `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
);

const PlayerScroller = styled.div(
  (props) => `
  margin-bottom: ${props.theme.size.base * 18}px;
  min-height: calc(100vh - ${props.theme.size.base * 18}px);
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  ${
    (props.breakpoints.md || props.breakpoints.lg) &&
    `
    flex-grow: 1;
    min-height: calc(100vh - ${props.theme.size.base * 22}px);
  `
  }; 
  ${
    props.breakpoints.xl &&
    `
    flex-grow: 1;
    min-height: calc(100vh - ${props.theme.size.base * 26}px);
  `
  }; 

  &::-webkit-scrollbar {
    width: 4px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    background: ${props.theme.color.primary[40]};
    background: linear-gradient(
      30deg,
      ${props.theme.color.primary[35]},
      ${props.theme.color.accent[50]}
    );
    border: 0;
  }
`
);

const PlayerShadow = styled.div(
  (props) => `
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 0 ${props.theme.size.base * 4}px ${
    props.theme.color.background[0]
  };
`
);
const RecordPlayer = styled.div(
  (props) => `
  background: ${props.theme.color.background[10]};
  background: linear-gradient(
    30deg,
    ${props.theme.color.background[10]},
    ${props.theme.color.background[5]}
  );
  pointer-events: auto;
  height: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-height: ${props.theme.size.base * 24}px;
  ${
    (props.breakpoints.xs || props.breakpoints.sm) &&
    props.breakpoints.portrait &&
    `
    flex-grow: 1;
  `
  }; 
  ${
    (props.breakpoints.xs || props.breakpoints.sm) &&
    !props.breakpoints.portrait &&
    `
    flex-direction: row;
    align-items: flex-end;
  `
  };  
`
);

const PlaylistWrapper = styled.div(
  (props) => `
  pointer-events: auto;
  flex: 1 1 0;
  height: auto;
  z-index: 100;
  background: ${props.theme.color.background[30]};
  background: linear-gradient(
    30deg,
    ${props.theme.color.background[0]},
    ${props.theme.color.background[10]}
  );
  width: 100%;
`
);

const Loading = styled.div(
  (props) => `
    min-height: calc(100vh - ${props.theme.size.base * 17}px);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: ${props.theme.size.base * 40}px;
      height: ${props.theme.size.base * 40}px;
      border-radius: ${props.theme.size.base * 20}px;
      transform: translate(-50%, -${props.theme.size.base * 64}px);
      animation: loadingAnimation 1s linear 0s infinite normal both;
    }

    @keyframes loadingAnimation {
      0.0% {
        box-shadow: inset 2px 2px 3px -1px ${props.theme.color.accent[70]};
      }
      100% {
        box-shadow: inset 2px 2px 3px -1px ${props.theme.color.accent[70]};
      }
      24.8% {
        box-shadow: inset -2px 2px 3px -1px ${props.theme.color.accent[70]};
      }
      50.2% {
        box-shadow: inset -2px -2px 3px -1px ${props.theme.color.accent[70]};
      }
      75.0% {
        box-shadow: inset 2px -2px 3px -1px ${props.theme.color.accent[70]};
      }
    }
    ${
      props.breakpoints.lg &&
      `
      min-height: calc(100vh - ${props.theme.size.base * 20}px);
    `
    };
    ${
      props.breakpoints.xl &&
      `
      min-height: calc(100vh - ${props.theme.size.base * 24}px);
    `
    };
  `
);

const Player = (props) => {
  const breakpoints = useBreakpoint();
  // refactor to use new endpoints
  const [nowPlaying, setNowPlaying] = useState([]);
  const [playlist, setPlaylist] = useState([]);

  const [shows, setShows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [today, setToday] = useState(Date());
  const [pageTitle, setPageTitle] = useState('');
  const [albumTheme, setAlbumTheme] = useState([]);
  const [progress, setProgress] = useState({
    playedSeconds: 0,
    played: 0,
    loadedSeconds: 0,
    loaded: 0,
  });
  const [duration, setDuration] = useState(0);
  const playerRef = useRef();

  const sanitizedString = (string) => {
    const parsedString = string.toString();
    // sanitize string to avoid any html or other malicious code special characters
    return parsedString.replace(/[^a-zA-Z0-9 '"`]/g, '');
  };

  const handleSetNowPlaying = (data) => {
    const sanitizedData = data;
    sanitizedData.start = new Date(sanitizedData.start) || Date();
    sanitizedData.end = new Date(sanitizedData.end) || Date();
    sanitizedData.duration = parseInt(sanitizedData.duration) || 1200;
    sanitizedData.artist = sanitizedString(sanitizedData.artist) || 'KUAA';
    sanitizedData.release = sanitizedString(sanitizedData.release) || 'KUAA';
    sanitizedData.song = sanitizedString(sanitizedData.song) || 'KUAA';
    setNowPlaying(sanitizedData);
  };

  const getPosts = async () => {
    try {
      const nowPlayingData = await axios.get(
        'https://kuaa-api-7xgcl.ondigitalocean.app/nowplaying'
      );
      if (nowPlayingData.data) {
        nowPlayingData.data.image =
          nowPlayingData.data.image ||
          nowPlayingData.data.ShowInfo.image ||
          placeHolderImage;
        handleSetNowPlaying(nowPlayingData.data);
      }

      const playlistData = await axios.get(
        'https://kuaa-api-7xgcl.ondigitalocean.app/recentplays/25'
      );
      if (playlistData.data) {
        setPlaylist(playlistData.data);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  // Set update loop
  useEffect(() => {
    setToday(Date());
    getPosts();
    const interval = setInterval(() => {
      setToday(Date());
      getPosts();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // On Posts update
  useEffect(() => {
    if (
      !isLoading && // is not loading
      nowPlaying?.ShowInfo && // nowPlaying exists
      props.context.tracklist && // if player context exists
      props.context?.currentTrack.id === props.context.tracklist[0].id // if listening to live stream
    ) {
      setPageTitle(`${nowPlaying.song} - ${nowPlaying.artist}  |  `);
      // Sync with nowPlaying
      if (props.theme.sync) {
        const themeset = generateTheme(
          nowPlaying.image ? nowPlaying.theme : nowPlaying.ShowInfo.theme,
          nowPlaying.image ? nowPlaying.image : nowPlaying.ShowInfo.image,
          nowPlaying.release
        );

        setAlbumTheme({
          themeLight: themeset.themeLight,
          themeDark: themeset.themeDark,
          dark: props.theme.dark,
          colorSync: true,
          localStorage: false,
          preferredTheme: false,
        });
      }
    } else if (!isLoading && props.context.tracklist) {
      // Listening to tracklist
      setPageTitle(props.context.currentTrack.name);
    }
  }, [isLoading, shows, props.theme.sync, nowPlaying]);

  // On Album Theme update
  useEffect(() => {
    if (albumTheme.themeDark) {
      if (albumTheme.themeDark.themeName !== props.theme.themeName) {
        props.theme.setTheme(
          albumTheme.themeLight,
          albumTheme.themeDark,
          albumTheme.dark,
          albumTheme.colorSync,
          albumTheme.localStorage,
          albumTheme.preferredTheme
        );
      }
    }
  }, [albumTheme]);

  const handleProgress = (state) => {
    setProgress(state);
  };
  const handleDuration = (e) => {
    setDuration(e);
  };
  const combineDjs = (djs) => {
    // comma seperated list of djs
    if (!djs) {
      return '';
    }
    return djs.map((dj) => dj.name).join(', ');
  };

  return (
    <PlayerWrapper theme={props.theme} breakpoints={breakpoints}>
      <Helmet title={`KUAA 99.9 FM Salt Lake City | ${pageTitle}`} />

      {!isLoading && nowPlaying && nowPlaying.ShowInfo ? (
        (!props.context.miniPlayer ||
          breakpoints.md ||
          breakpoints.lg ||
          breakpoints.xl) && (
          <PlayerScroller theme={props.theme} breakpoints={breakpoints}>
            <RecordPlayer theme={props.theme} breakpoints={breakpoints}>
              <Logo theme={props.theme} context={props.context}></Logo>
              {nowPlaying && (
                <>
                  {props.context.tracklist &&
                  props.context?.currentTrack.id ===
                    props.context.tracklist[0].id ? (
                    <>
                      <TrackBackground
                        theme={props.theme}
                        title={`${nowPlaying.artist} ${nowPlaying.release} background`}
                        image={nowPlaying.image}
                        playing={props.context.playing}
                      ></TrackBackground>
                      <TrackArt
                        theme={props.theme}
                        image={nowPlaying.image}
                        title={`${nowPlaying.artist} ${nowPlaying.release}`}
                        playing={props.context.playing}
                        progress={progress.loadedSeconds}
                      ></TrackArt>
                      <TrackInfo
                        theme={props.theme}
                        song={nowPlaying.song}
                        artist={nowPlaying.artist}
                        release={nowPlaying.release}
                        start={nowPlaying.start.toString()}
                        duration={nowPlaying.duration.toString()}
                        today={today}
                      ></TrackInfo>
                    </>
                  ) : (
                    <>
                      <TrackArt
                        theme={props.theme}
                        image={placeHolderImage}
                        title={`${props.context.currentTrack.name}`}
                        playing={props.context.playing}
                        progress={progress.loadedSeconds}
                      ></TrackArt>
                      <TrackInfo
                        theme={props.theme}
                        song={props.context.currentTrack.name}
                        artist={combineDjs(props.context.currentTrack.djs)}
                        x
                        today={today}
                      ></TrackInfo>
                    </>
                  )}
                </>
              )}
            </RecordPlayer>
            <Tracklist context={props.context} theme={props.theme} />
            {props.context.showPlaylist && (
              <PlaylistWrapper theme={props.theme} breakpoints={breakpoints}>
                <PlayerPlaylist
                  context={props.context}
                  theme={props.theme}
                  posts={playlist}
                  path={props.path}
                  today={today}
                ></PlayerPlaylist>
              </PlaylistWrapper>
            )}
            <PlayerShadow theme={props.theme}></PlayerShadow>
          </PlayerScroller>
        )
      ) : (
        <Loading theme={props.theme} breakpoints={breakpoints}></Loading>
      )}

      {props.context.currentTrack && (
        <ReactPlayer
          ref={playerRef}
          playing={props.context.playing}
          url={props.context.currentTrack.url}
          width='100%'
          height='0px'
          volume={props.context.volume}
          muted={props.context.mute}
          playsinline={true}
          loop={false}
          controls={false}
          onProgress={handleProgress}
          onBuffer={handleProgress}
          onDuration={handleDuration}
          onEnded={() => (
            props.context.removeFromTracklist(props.context.currentTrack),
            props.context.playNextTrack()
          )}
          config={{
            file: {
              forceAudio: true,
            },
          }}
        />
      )}
      {nowPlaying && (breakpoints.xs || breakpoints.sm) && (
        <MiniPlayer
          theme={props.theme}
          post={nowPlaying}
          show={nowPlaying.ShowInfo}
          playing={props.context.playing}
          progress={progress.loadedSeconds}
          path={props.path}
        ></MiniPlayer>
      )}
      <Controls
        theme={props.theme}
        path={props.path}
        nowPlaying={nowPlaying}
        progress={progress}
        duration={duration}
      ></Controls>
    </PlayerWrapper>
  );
};

const PlayerContainer = ({ children, path }) => (
  <PlayerContext.Consumer>
    {(context) => (
      <ThemeContext.Consumer>
        {(theme) => <Player theme={theme} context={context} path={path} />}
      </ThemeContext.Consumer>
    )}
  </PlayerContext.Consumer>
);

export default PlayerContainer;
