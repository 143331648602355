import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Progress from './Progress';

import PlayerContext from '../../context/PlayerContext';
import Button from '../Button';
import ButtonOutline from '../ButtonOutline';
import Volume from './Volume';

import SvgPlay from '../../icons/Play';
import SvgPause from '../../icons/Pause';
import SvgVolume from '../../icons/Volume';
import SvgList from '../../icons/List';
import SvgClose from '../../icons/Close';
import SvgHeart from '../../icons/Heart';
import SvgHeartSolid from '../../icons/HeartSolid';
import SvgBroadcast from '../../icons/Broadcast';
import SvgCaretRight from '../../icons/CaretRight';
import Badge from '../Badge';

const StatsStyles = styled.div(
  (props) => `
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props.theme.size.base * 2}px;
`
);
const ControlsStyles = styled.div(
  (props) => `
  position: absolute;
  bottom: 0;
  pointer-events: auto;
  width: calc(100% - ${props.theme.size.base * 4}px);
  z-index: 1000;
  padding: ${props.theme.size.base * 2}px;
  background: ${props.theme.color.background[75]};
  ${
    props.theme.dark
      ? `
      background: linear-gradient(
        30deg,
        ${props.theme.color.primary[15]},
        ${props.theme.color.background[75]}
      );
    `
      : `
    background: ${props.theme.color.tertiary[95]};
    background: linear-gradient(
      30deg,
      ${props.theme.color.tertiary[90]},
      ${props.theme.color.background[85]}
    );
    `
  }

  box-shadow: 0 ${props.theme.size.base}px ${props.theme.size.base * 4}px ${
    props.theme.color.background[10]
  };
  border-radius: 0;
  .top-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${props.theme.size.base * 2}px;
  }
  .bottom-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${props.theme.size.base * 2}px;
    padding-top: ${props.theme.size.base * 2}px;
  }

  transition: width 450ms ease, padding 450ms ease;

  ${
    (props.breakpoints.xs || props.breakpoints.sm) &&
    `
    z-index: 1000;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    &.mini-player {
      padding: ${props.theme.size.base * 2}px ${props.theme.size.base * 2}px;
      border-radius: ${props.theme.size.base * 4}px
        ${props.theme.size.base * 4}px 0 0;
      width: ${props.theme.size.base * 20}px;
    }
  `
  };
`
);

const Overlay = styled.div(
  (props) => `
    position: fixed;
    z-index: 1100;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `
);


const Controls = (props) => {
  const breakpoints = useBreakpoint();

  return (
    <PlayerContext.Consumer>
      {(context) => (
        <>
          <ControlsStyles
            theme={props.theme}
            className={
              context.miniPlayer && (breakpoints.xs || breakpoints.sm)
                ? 'mini-player'
                : null
            }
            breakpoints={breakpoints}
          >
            <div className='top-row'>
              <ButtonOutline
                onClick={() => context.setShowVolume(!context.showVolume)}
                active={context.showVolume}
                square={true}
                shape={4}
                size={
                  context.miniPlayer && (breakpoints.xs || breakpoints.sm)
                    ? -1
                    : 0
                }
                color='secondary'
                label={'Open Volume Controls'}
              >
                <SvgVolume></SvgVolume>
              </ButtonOutline>

              {context.showVolume && (
                <>
                  <Overlay
                    onClick={() => context.setShowVolume(false)}
                  ></Overlay>
                  <Volume 
                    theme={props.theme}
                    showVolume={context.showVolume}
                    setShowVolume={context.setShowVolume}
                    volume={context.volume}
                    setVolume={context.setVolume}
                    mute={context.mute}
                    setMute={context.setMute}
                    mini={context.miniPlayer && (breakpoints.xs || breakpoints.sm)}
                  ></Volume>
                </>
              )}

              <Button
                color='secondary'
                onClick={() => context.setPlaying(!context.playing)}
                shape={4}
                size={
                  context.miniPlayer && (breakpoints.xs || breakpoints.sm)
                    ? -1
                    : 1
                }
                square={true}
                active={context.playing}
                spinner={props.progress.loadedSeconds === 0 ? true : false}
                label={context.playing ? 'Pause' : 'Play'}
              >
                {context.playing ? <SvgPause></SvgPause> : <SvgPlay></SvgPlay>}
              </Button>

              {context.doILikeThisSong &&
              context.users &&
              context.tracklist &&
              context.currentTrack?.id === context.tracklist[0].id ? (
                <ButtonOutline
                  square={true}
                  shape={4}
                  size={
                    context.miniPlayer && (breakpoints.xs || breakpoints.sm)
                      ? -1
                      : 0
                  }
                  color='secondary'
                  label={
                    context.doILikeThisSong(props.nowPlaying.SongID)
                      ? 'Like current song'
                      : 'Unlike current song'
                  }
                  onClick={() => {
                    context.sendLike(props.nowPlaying);
                  }}
                  active={context.doILikeThisSong(props.nowPlaying.SongID)}
                >
                  {context.doILikeThisSong(props.nowPlaying.SongID) ? (
                    <SvgHeartSolid></SvgHeartSolid>
                  ) : (
                    <SvgHeart></SvgHeart>
                  )}
                </ButtonOutline>
              ) : (
                <ButtonOutline
                  square={true}
                  shape={4}
                  size={
                    context.miniPlayer && (breakpoints.xs || breakpoints.sm)
                      ? -1
                      : 0
                  }
                  color='secondary'
                  onClick={() => {
                    context.playNextTrack();
                  }}
                  label='Play Next Track'
                >
                  <SvgCaretRight></SvgCaretRight>
                </ButtonOutline>

                // <ButtonOutline
                // square={true}
                // shape={4}
                // size={
                //   context.miniPlayer && (breakpoints.xs || breakpoints.sm)
                //     ? -1
                //     : 0
                // }
                // color='secondary'
                // active={context.showPlaylist}
                // onClick={() => {
                //   context.setShowPlaylist(!context.showPlaylist);
                // }}
                // label='Playlist'
                // >
                // <SvgList></SvgList>
                // </ButtonOutline>
              )}
            </div>
            {/* <ButtonOutline
            className='toggle-miniplayer'
            square={true}
            shape={4}
            color='tertiary'
            active={context.miniPlayer}
            onClick={() => context.setMiniPlayer(!context.miniPlayer)}
          >
            m
          </ButtonOutline> */}
            {context.currentTrack &&
              context.tracklist &&
              (context.currentTrack?.id !== context.tracklist[0].id ? (
                <Progress
                  progress={props.progress}
                  duration={props.duration}
                  theme={props.theme}
                  title={context.currentTrack?.name}
                />
              ) : context.miniPlayer && (breakpoints.xs || breakpoints.sm) ? (
                <></>
              ) : (
                <div className='bottom-row'>
                  {context.users && (
                    <Badge color='tertiary'>
                      <SvgBroadcast
                        style={{ marginRight: props.theme.size.base * 1 }}
                      />
                      {context.users.length}
                    </Badge>
                  )}
                  {context.doILikeThisSong && context.users && (
                    <Badge
                      color='tertiary'
                      onClick={() => {
                        context.sendLike(props.nowPlaying);
                      }}
                    >
                      {context.doILikeThisSong(props.nowPlaying.SongID) ? (
                        <SvgHeartSolid
                          style={{ marginRight: props.theme.size.base * 1 }}
                        ></SvgHeartSolid>
                      ) : (
                        <SvgHeart
                          style={{ marginRight: props.theme.size.base * 1 }}
                        ></SvgHeart>
                      )}
                      {context.likesong
                        ? context.getSongLikes(props.nowPlaying.SongID)
                        : 0}
                    </Badge>
                  )}
                  <ButtonOutline
                    size={-2}
                    shape={4}
                    color='tertiary'
                    active={context.showPlaylist}
                    onClick={() => {
                      context.setShowPlaylist(!context.showPlaylist);
                    }}
                    label={
                      context.showPlaylist ? 'Show Playlist' : 'Hide Playlist'
                    }
                  >
                    {context.showPlaylist ? (
                      <SvgClose
                        style={{ marginRight: props.theme.size.base * 1 }}
                      ></SvgClose>
                    ) : (
                      <SvgList
                        style={{ marginRight: props.theme.size.base * 1 }}
                      ></SvgList>
                    )}
                    Playlist
                  </ButtonOutline>
                </div>
              ))}
          </ControlsStyles>
        </>
      )}
    </PlayerContext.Consumer>
  );
};

Controls.propTypes = {
  theme: PropTypes.object,
  progress: PropTypes.object,
  path: PropTypes.string,
  nowPlaying: PropTypes.object,
};

export default Controls;
