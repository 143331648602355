import React, { useContext } from 'react';
import reduce from 'lodash/reduce';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from 'gatsby';

import ThemeContext from '../context/ThemeContext';
import PlayerContext from '../context/PlayerContext';

import Logo from './Logo';

const NavItems = styled.nav(
  (props) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  ${
    (props.breakpoints.md || props.breakpoints.lg || props.breakpoints.xl) &&
    `
    flex-direction: row;
  `
  };
`
);

const NavbarStyles = styled.nav(
  (props) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${props.theme.size.base * 12}px ${props.theme.size.base * 4}px ${
    props.theme.size.base * 4
  }px;
  overflow-x: auto;
  overflow-y: hidden;
  height: calc(100vh - ${props.theme.size.base * 16}px);

  &::-webkit-scrollbar {
    height: 4px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    background: ${props.theme.color.secondary[40]};
    background: linear-gradient(
      30deg,
      ${props.theme.color.secondary[35]},
      ${props.theme.color.tertiary[50]}
      );
    border: 0;
  }
  .navbar-item {
    position: relative; 
    top: 0; 
    left: 0; 
    font-size: ${props.theme.size.fontSize[5]};
    line-height: ${props.theme.size.lineHeight[5]};
    color: ${props.theme.color.foreground[70]};
    white-space: nowrap;
    text-decoration: none;
    padding: ${props.theme.size.base}px ${props.theme.size.base * 2}px;
    font-weight: 600;
    letter-spacing: 0.1em;
      ${
        props.theme.dark
          ? `
        text-shadow: 1px 1px ${props.theme.color.secondary[40]}, 
          2px 2px ${props.theme.color.secondary[50]};
      `
          : `
        text-shadow: 1px 1px ${props.theme.color.secondary[70]}, 
          2px 2px ${props.theme.color.secondary[60]};
      `
      };
    transition: color 450ms ease, text-shadow 450ms ease, top 450ms ease, left 450ms ease;
    &:hover {
      top: -2px; 
      left: -2px; 
      color: ${props.theme.color.foreground[100]};
      ${
        props.theme.dark
          ? `
        text-shadow: 1px 1px ${props.theme.color.tertiary[45]}, 
          2px 2px ${props.theme.color.tertiary[40]}, 
          3px 3px ${props.theme.color.tertiary[35]},
          4px 4px ${props.theme.color.tertiary[30]};
      `
          : `
        text-shadow: 1px 1px ${props.theme.color.tertiary[75]}, 
          2px 2px ${props.theme.color.tertiary[80]}, 
          3px 3px ${props.theme.color.tertiary[65]},
          4px 4px ${props.theme.color.tertiary[60]};
      `
      }
    }
    &.active {
      color: ${props.theme.color.primary[60]};
      ${
        props.theme.dark
          ? `
        text-shadow: 1px 1px ${props.theme.color.tertiary[40]}, 
          2px 2px ${props.theme.color.tertiary[50]};
      `
          : `
        text-shadow: 1px 1px ${props.theme.color.tertiary[70]}, 
          2px 2px ${props.theme.color.tertiary[60]};
      `
      }
      &:hover {
        ${
          props.theme.dark
            ? `
          text-shadow: 1px 1px ${props.theme.color.tertiary[45]}, 
            2px 2px ${props.theme.color.tertiary[40]}, 
            3px 3px ${props.theme.color.tertiary[35]},
            4px 4px ${props.theme.color.tertiary[30]};
        `
            : `
          text-shadow: 1px 1px ${props.theme.color.tertiary[75]}, 
            2px 2px ${props.theme.color.tertiary[80]}, 
            3px 3px ${props.theme.color.tertiary[65]},
            4px 4px ${props.theme.color.tertiary[60]};
        `
        }
      }
    }
  }
  ${
    (props.breakpoints.md || props.breakpoints.lg || props.breakpoints.xl) &&
    `
    padding: ${props.theme.size.base * 2}px ${props.theme.size.base * 2}px;
    height: auto;
    flex-direction: row;
    .navbar-item {
      font-size: ${props.theme.size.fontSize[3]};
      line-height: ${props.theme.size.lineHeight[3]};
    }
  `
  };
  `
);
const Navbar = ({ location }) => {
  const breakpoints = useBreakpoint();
  return (
    <PlayerContext.Consumer>
      {(context) => (
        <ThemeContext.Consumer>
          {(theme) => (
            <NavbarStyles
              theme={theme}
              breakpoints={breakpoints}
              role='navigation'
              aria-label='main-navigation'
            >
              {(breakpoints.xs || breakpoints.sm) && (
                <Logo theme={theme} context={context}></Logo>
              )}
              <NavItems breakpoints={breakpoints}>
                <Link
                  className={`navbar-item ${
                    location.pathname === '/' ? 'active' : ''
                  }`}
                  to='/'
                  onClick={() => context.setMiniNav(true)}
                >
                  Home
                </Link>
                <Link
                  className={`navbar-item ${
                    location.pathname.split('/')[1] === 'shows' ? 'active' : ''
                  }`}
                  to='/schedule'
                  onClick={() => context.setMiniNav(true)}
                >
                  Schedule
                </Link>
                <Link
                  className={`navbar-item ${
                    location.pathname.split('/')[1] === 'djs' ? 'active' : ''
                  }`}
                  to='/djs'
                  onClick={() => context.setMiniNav(true)}
                >
                  DJs
                </Link>
                <Link
                  className={`navbar-item ${
                    location.pathname.split('/')[1] === 'archive'
                      ? 'active'
                      : ''
                  }`}
                  to='/archive'
                  onClick={() => context.setMiniNav(true)}
                >
                  Archive
                </Link>
                {/* <Link
                  className={`navbar-item ${
                    location.pathname.split('/')[2] === 'underwriting'
                      ? 'active'
                      : ''
                  }`}
                  to='/underwriting'
                  onClick={() => context.setMiniNav(true)}
                >
                  Underwriting
                </Link> */}
                <Link
                  className={`navbar-item ${
                    location.pathname.split('/')[1] === 'donate' ? 'active' : ''
                  }`}
                  to='/donate'
                  onClick={() => context.setMiniNav(true)}
                >
                  Donate
                </Link>
                <a
                  className={`navbar-item ${
                    location.pathname.split('/')[1] === 'shop' ? 'active' : ''
                  }`}
                  href='https://shop.kuaafm.org/collections/all'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Shop
                </a>
                <Link
                  className={`navbar-item ${
                    location.pathname.split('/')[1] === 'blog' ? 'active' : ''
                  }`}
                  to='/blog'
                  onClick={() => context.setMiniNav(true)}
                >
                  Blog
                </Link>
                {/* <Link
                  className={`navbar-item ${
                    location.pathname.split('/')[1] === 'contact'
                      ? 'active'
                      : ''
                  }`}
                  to='/contact'
                  onClick={() => context.setMiniNav(true)}
                >
                  Contact
                </Link> */}
              </NavItems>
            </NavbarStyles>
          )}
        </ThemeContext.Consumer>
      )}
    </PlayerContext.Consumer>
  );
};

export default Navbar;
