import * as React from "react";
const SvgFacebook = (props) => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={30}
      d="M296.863 451.769c.009-.258.018-.545.018-.867-.011-59.812-.01-60.611 0-120.423 0-3.166-.287-2.828 2.755-2.829q27.834-.015 55.668 0c.58 0 1.163-.042 1.739.007 1.122.1 1.572-.334 1.74-1.507.948-6.629 1.985-13.246 3.006-19.865q4.105-26.6 8.219-53.193c.335-2.17.293-2.208-1.938-2.242-.58-.009-1.16 0-1.74 0h-66.109c-.663 0-1.327-.029-1.988 0-.907.039-1.42-.284-1.356-1.272.027-.412 0-.828 0-1.242v-48.214a48.723 48.723 0 0 1 1.117-10.59c3.171-14.3 11.682-23.6 25.823-27.647a58.75 58.75 0 0 1 15.694-2.061c10.437-.092 20.876-.028 31.313-.026 3.062 0 2.629.037 2.631-2.722q.015-28.95.008-57.9a24.052 24.052 0 0 1 .023-2.979c.167-1.337-.533-1.769-1.654-1.918a91.65 91.65 0 0 1-1.474-.209c-6.868-1.015-13.76-1.83-20.659-2.614a334.941 334.941 0 0 0-36.142-2.093 125.088 125.088 0 0 0-17.062 1.125A103.638 103.638 0 0 0 262 100.907a82.385 82.385 0 0 0-35.819 35.71c-8.189 15.63-11.827 32.466-12.12 49.955-.341 20.373-.1 40.757-.123 61.136 0 3.407.471 3.132-3.24 3.133q-30.442.009-60.887 0c-.662 0-1.325.022-1.988 0-.93-.025-1.378.383-1.318 1.339.032.5 0 .993 0 1.49v71.079c0 3.185-.294 2.884 2.983 2.885q30.816.009 61.632 0c3.171 0 2.815-.219 2.815 2.787v120.424c0 .3.022.606.035.914a267.6 267.6 0 0 0 82.9 0Z"
    />
  </svg>
);
export default SvgFacebook;
