import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import PlayerContext from '../../context/PlayerContext';
import Button from '../Button';
import ButtonOutline from '../ButtonOutline';
import placeHolderImage from '../../img/record-texture.png';

import SvgClose from '../../icons/Close';
import SvgHeart from '../../icons/Heart';
import SvgHeartSolid from '../../icons/HeartSolid';

var tinycolor = require('tinycolor2');

const MiniPlayerStyles = styled.div(
  (props) => `
  pointer-events: auto;
  position: fixed;
  z-index: 1000;
  top: ${props.theme.size.base * 3}px;
  right: ${props.theme.size.base * 3}px;
  width: ${props.height};
  height: ${props.height};
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .container {
    z-index: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 ${props.theme.size.base}px ${props.theme.size.base * 4}px ${
    props.theme.color.background[10]
  };
    width: ${props.theme.size.base * 8}px;
    height: ${props.theme.size.base * 8}px;
    border-radius: 999px;
    transition: box-shadow 450ms ease;
    &.playing {
      box-shadow: 0 ${props.theme.size.base * 2}px ${
    props.theme.size.base * 4
  }px ${props.theme.color.background[10]};
    }
  }
  .record-outer {
    width: ${props.theme.size.base * 8}px;
    height: ${props.theme.size.base * 8}px;
    border-radius: 999px;
    overflow: hidden;
    background: ${props.theme.color.accent[15]};
    &.playing {
      animation-name: rotation;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-duration: 6s;
      animation-fill-mode: both;
    }
  }
  .record-inner {
    z-index: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${props.theme.size.base * 7}px;
    height: ${props.theme.size.base * 7}px;
    border-radius: 999px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .toggle-miniplayer {
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .badge {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    min-width: ${props.theme.size.base * 3}px;
    height: ${props.theme.size.base * 3}px;
    border-radius: 999px;
    background: ${props.theme.color.primary[35]};
    color:
    ${tinycolor
      .mostReadable(props.theme.color.background[100], [
        props.theme.color.primary[75],
        props.theme.color.primary[40],
      ])
      .toHexString()};;
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tack-info {
    transition: width 450ms ease, padding 450ms ease, opacity 450ms ease;
    text-align: right;
    z-index: 100;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    height: ${props.theme.size.base * 6}px;
    width: ${props.theme.size.base * 8}px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: ${props.theme.size.fontSize[1]};
    line-height: ${props.theme.size.lineHeight[1]};
    padding: ${props.theme.size.base * 1}px 0;
    border-radius: 999px;
    background: ${props.theme.color.background[35]};
    background: linear-gradient(
      30deg,
      ${props.theme.color.background[30]},
      ${props.theme.color.background[40]}
    );
    box-shadow: 0 0 ${props.theme.size.base * 8}px ${
    props.theme.color.background[20]
  };
    .post-text {
      max-width: ${props.theme.size.base * 18}px;
    }

    .post-song,
    .post-artist,
    .post-release {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .post-song {
      color: ${props.theme.color.foreground[80]};
      transition: color 450ms ease;
    }
    .likes-stat {
      position: absolute;
      left: ${props.theme.size.base * 2}px;
      color: ${props.theme.color.secondary[60]};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${props.theme.size.fontSize[3]};
      line-height: ${props.theme.size.lineHeight[3]};
      cursor: pointer;
      transition: color 450ms ease;
      &:hover {
        color: ${props.theme.color.foreground[90]};
      }
    }
    .post-artist {
      font-weight: 600;
      color: ${props.theme.color.primary[50]};
      transition: color 450ms ease;
    }
    .post-release {
      color: ${props.theme.color.foreground[40]};
      transition: color 450ms ease;
    }
  }
  &:hover {
    .tack-info {
      opacity: 1;
      width: ${props.theme.size.base * 24}px;
      padding: ${props.theme.size.base * 1}px ${props.theme.size.base * 10}px ${
    props.theme.size.base * 1
  }px ${props.theme.size.base * 4}px;
    }
  }

`
);

const MiniPlayer = (props) => {
  const height = `${props.theme.size.base * 8}px`;

  return (
    <PlayerContext.Consumer>
      {(context) => (
        <MiniPlayerStyles theme={props.theme} height={height}>
          {context.miniPlayer && (
            <>
              <div
                className={props.playing ? 'playing container' : 'container'}
              >
                <div
                  className={
                    props.playing && props.progress > 0
                      ? 'playing record-outer'
                      : 'record-outer'
                  }
                >
                  <div className='record-inner'>
                    {context.tracklist &&
                    context?.currentTrack.id === context.tracklist[0].id ? (
                      <>
                        {props.post && props.show ? (
                          <img
                            src={props.post.image}
                            alt={
                              props.post.artist +
                              ' ' +
                              props.post.release +
                              ' album artwork'
                            }
                          />
                        ) : (
                          <img
                            width='170'
                            src={props.theme.themeImage}
                            alt={
                              props.post.artist +
                              ' ' +
                              props.post.release +
                              ' placeholder artwork'
                            }
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <img
                          width='170'
                          src={placeHolderImage}
                          alt={context.currentTrack.name}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className='tack-info'>
                {context.tracklist &&
                context?.currentTrack.id === context.tracklist[0].id ? (
                  <>
                    {context.users && (
                      <div
                        className='likes-stat'
                        onClick={() => {
                          context.sendLike(props.post);
                        }}
                      >
                        {context.doILikeThisSong(props.post.SongID) ? (
                          <SvgHeartSolid
                            style={{ marginRight: props.theme.size.base * 0.5 }}
                          />
                        ) : (
                          <SvgHeart
                            style={{ marginRight: props.theme.size.base * 0.5 }}
                          />
                        )}
                        {context.likesong
                          ? context.getSongLikes(props.post.SongID)
                          : 0}
                      </div>
                    )}
                    {props.post && props.show && (
                      <div className='post-text'>
                        <div className='post-song'>
                          {props.post.song}
                        </div>
                        <div className='post-artist'>
                          {props.post.artist}
                        </div>
                        <div className='post-release'>
                          {props.post.release}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className='post-text'>
                    <div className='post-song'>{context.currentTrack.name}</div>
                  </div>
                )}
              </div>
            </>
          )}

          {context.miniPlayer ? (
            <>
              <ButtonOutline
                className='toggle-miniplayer'
                square={true}
                shape={4}
                size={1}
                color='secondary'
                onClick={() => {
                  context.setShowPlaylist(false);
                  context.setMiniPlayer(!context.miniPlayer);
                }}
                label='Show Track Information'
              ></ButtonOutline>
              {context.tracklist?.length > 1 && (
                <div className='badge'>{context.tracklist?.length - 1}</div>
              )}
            </>
          ) : (
            <Button
              className='toggle-miniplayer'
              square={true}
              shape={4}
              size={1}
              color='secondary'
              active={true}
              onClick={() => {
                context.setShowPlaylist(false);
                context.setMiniPlayer(!context.miniPlayer);
              }}
              label='Hide Track Information'
            >
              <SvgClose></SvgClose>
            </Button>
          )}
        </MiniPlayerStyles>
      )}
    </PlayerContext.Consumer>
  );
};

MiniPlayer.propTypes = {
  theme: PropTypes.object,
  show: PropTypes.object,
  post: PropTypes.object,
  playing: PropTypes.bool,
  progress: PropTypes.number,
  path: PropTypes.string,
};

export default MiniPlayer;
