import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import recordTextureImage from '../../img/record-texture.svg';

const TrackArtStyles = styled.div(
  (props) => `
  flex-grow: 1;
  position: relative;
  width: 100%;
  overflow: hidden;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .container {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 8px 32px ${props.theme.color.background[10]};
    width: 130%;
    padding-bottom: 130%;
    height: 0;
    border-radius: 999px;
    transition: box-shadow 450ms ease, transform 450ms ease;
    &.playing {
      transform: translate(-50%, -50%) scale(1.05);
      box-shadow: 0 24px 64px ${props.theme.color.background[10]};
    }
  }
  .record-outer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 999px;
    overflow: hidden;
    ${
      props.theme.dark
        ? `
      background: ${props.theme.color.tertiary[25]};
      background: linear-gradient(
        30deg,
        ${props.theme.color.tertiary[20]},
        ${props.theme.color.primary[15]},
        ${props.theme.color.secondary[20]}
      );
      `
        : `
      background: ${props.theme.color.tertiary[85]};
      background: linear-gradient(
        30deg,
        ${props.theme.color.tertiary[85]},
        ${props.theme.color.primary[80]},
        ${props.theme.color.secondary[85]}
      );
      `
    }
    &:before {
      z-index: 500;
      content: ' ';
      ${
        props.theme.dark
          ? `
          background: ${props.theme.color.tertiary[15]};
          background: linear-gradient(
            30deg,
            ${props.theme.color.tertiary[25]},
            ${props.theme.color.primary[20]},
            ${props.theme.color.secondary[25]}
          );
        `
          : `
        background: ${props.theme.color.tertiary[90]};
        background: linear-gradient(
          30deg,
          ${props.theme.color.tertiary[90]},
          ${props.theme.color.primary[85]},
          ${props.theme.color.secondary[90]}
        );
        `
      }
      position: absolute;
      top: ${props.theme.size.base}px;
      right: ${props.theme.size.base}px;
      bottom: ${props.theme.size.base}px;
      left: ${props.theme.size.base}px;
      border-radius: 999px;
    }
    &:after {
      z-index: 550;
      content: ' ';
      ${
        props.theme.dark
          ? `
        background: ${props.theme.color.tertiary[25]};
        background: linear-gradient(
          30deg,
          ${props.theme.color.tertiary[20]},
          ${props.theme.color.primary[15]},
          ${props.theme.color.secondary[20]}
        );
        `
          : `
        background: ${props.theme.color.tertiary[85]};
        background: linear-gradient(
          30deg,
          ${props.theme.color.tertiary[85]},
          ${props.theme.color.primary[80]},
          ${props.theme.color.secondary[85]}
        );
        `
      }
      position: absolute;
      top: 20%;
      right: 20%;
      bottom: 20%;
      left: 20%;
      border-radius: 999px;
    }
    
    &.playing {
      animation-name: rotation;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-duration: 6s;
      animation-fill-mode: both;
    }
  }
  .record-texture {
    z-index: 550;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 999px;
    overflow: hidden;
    background: url(${props.background});
  }
  .record-inner {
    z-index: 600;
    position: absolute;
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
    border-radius: 999px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &:after {
      content: ' ';
      background: ${props.theme.color.background[10]};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 5%;
      height: 5%;
      border-radius: 999px;
    }
  }
  ${
    (props.breakpoints.xs || props.breakpoints.sm) &&
    `
    &.fixed {
      position: fixed;
      z-index: 600;
      top: 0;
      left: 0;
    }
  `
  };
  
  ${
    (props.breakpoints.xs || props.breakpoints.sm) &&
    props.breakpoints.portrait &&
    `
    flex-grow: 1;
  `
  }; 
  ${
    (props.breakpoints.xs || props.breakpoints.sm) &&
    !props.breakpoints.portrait &&
    `
    min-height: calc(100vh - ${props.theme.size.base * 12}px);
  `
  };

`
);

const TrackArt = (props) => {
  const breakpoints = useBreakpoint();
  return (
    <TrackArtStyles
      theme={props.theme}
      breakpoints={breakpoints}
      background={recordTextureImage}
    >
      <div className={props.playing ? 'playing container' : 'container'}>
        <div
          className={
            props.playing && props.progress > 0
              ? 'playing record-outer'
              : 'record-outer'
          }
        >
          <div className='record-texture'></div>
          <div className='record-inner'>
            {props.image ? (
              <img src={props.image} alt={props.title} />
            ) : (
              <img width='170' src={props.theme.themeImage} alt={props.title} />
            )}
          </div>
        </div>
      </div>
    </TrackArtStyles>
  );
};

TrackArt.propTypes = {
  theme: PropTypes.object,
  image: PropTypes.string,
  title: PropTypes.string,
  playing: PropTypes.bool,
  progress: PropTypes.number,
};

export default TrackArt;
