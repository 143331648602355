import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import PlayerContext from '../context/PlayerContext';
import Button from './Button';
import ButtonOutline from './ButtonOutline';

import SvgMenu from '../icons/Menu';
import SvgClose from '../icons/Close';

const MiniNavStyles = styled.div(
  (props) => `
  pointer-events: auto;
  position: fixed;
  z-index: 1000;
  top: ${props.theme.size.base * 3}px;
  left: ${props.theme.size.base * 3}px;
  width: ${props.height};
  height: ${props.height};
  
  .toggle-MiniNav {
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


`
);

const MiniNav = (props) => {
  const height = `${props.theme.size.base * 8}px`;

  return (
    <PlayerContext.Consumer>
      {(context) => (
        <MiniNavStyles theme={props.theme} height={height}>
          {context.miniNav ? (
            <Button
              className='toggle-miniNav'
              square={true}
              shape={4}
              size={1}
              color='secondary'
              onClick={() => {
                context.setMiniNav(!context.miniNav);
                context.setMiniPlayer(true);
              }}
              label='Open Primary Navigation'
            >
              <SvgMenu></SvgMenu>
            </Button>
          ) : (
            <ButtonOutline
              className='toggle-miniNav'
              square={true}
              shape={4}
              size={1}
              color='secondary'
              active={true}
              onClick={() => {
                context.setMiniNav(!context.miniNav);
                context.setShowPlaylist(false);
              }}
              label='Close Primary Navigation'
            >
              <SvgClose></SvgClose>
            </ButtonOutline>
          )}
        </MiniNavStyles>
      )}
    </PlayerContext.Consumer>
  );
};

MiniNav.propTypes = {
  theme: PropTypes.object,
};

export default MiniNav;
