import React, { useState, useEffect } from 'react';

import { createColorScale } from '../theme/utils/createColorScale';
import { themeDefault, themeDefaultDark } from '../theme/themeDefault';

const defaultState = {
  dark: true,
  size: {
    base: themeDefaultDark.size.base,
    fontSize: themeDefaultDark.size.fontSize,
    lineHeight: themeDefaultDark.size.lineHeight,
  },
  color: {
    primary: createColorScale(themeDefaultDark.color.primary),
    secondary: createColorScale(themeDefaultDark.color.secondary),
    tertiary: createColorScale(themeDefaultDark.color.tertiary),
    accent: createColorScale(themeDefaultDark.color.accent),
    grayscale: createColorScale(themeDefaultDark.color.grayscale),
    background: createColorScale(themeDefaultDark.color.background),
    foreground: createColorScale(themeDefaultDark.color.foreground),
  },
  preferredTheme: [],
  setDarkMode: () => {},
  setTheme: () => {},
};

const ThemeContext = React.createContext(defaultState);

const ThemeProvider = ({ url, children }) => {
  const _dark = true;
  const _sync = true;
  const _themeLight = themeDefault;
  const _themeDark = themeDefaultDark;

  const _theme = _dark ? generateTheme(_themeDark) : generateTheme(_themeLight);

  const [dark, setDark] = useState(_dark);
  const [sync, setSync] = useState(_sync);
  const [themeName, setThemeName] = useState(_theme.themeName);
  const [themeImage, setThemeImage] = useState(_theme.themeImage);

  const [size, setSize] = useState(_theme.size);
  const [color, setColor] = useState(_theme.color);
  const [themeLight, setThemeLight] = useState(_themeLight);
  const [themeDark, setThemeDark] = useState(_themeDark);

  const [preferredTheme, setPreferredTheme] = useState({
    dark: _themeDark,
    light: _themeLight,
  });

  // const getLocalStorage = (prop) => {
  //   const value = JSON.parse(localStorage.getItem(prop));
  //   return value;
  // };

  const setLocalStorage = (prop, value) => {
    localStorage.setItem(prop, JSON.stringify(value));
  };

  const setDarkMode = (_dark, _localStorage = true) => {
    const theme = _dark ? generateTheme(themeDark) : generateTheme(themeLight);

    setDark(_dark);
    setSize(theme.size);
    setColor(theme.color);
    if (_localStorage) {
      setLocalStorage('dark', _dark);
    }
  };

  const setTheme = (
    _themeLight,
    _themeDark,
    _dark,
    _colorSync = false,
    _localStorage = true,
    _preferredTheme = true
  ) => {
    const theme = _dark
      ? generateTheme(_themeDark)
      : generateTheme(_themeLight);
    setDark(_dark);
    setThemeName(theme.themeName);
    setThemeImage(theme.themeImage);
    setThemeLight(_themeLight);
    setThemeDark(_themeDark);
    setSync(_colorSync);
    setSize(theme.size);
    setColor(theme.color);

    if (_preferredTheme) {
      setPreferredTheme({ dark: _themeDark, light: _themeLight });
      if (_localStorage) {
        setLocalStorage('preferredTheme', {
          dark: _themeDark,
          light: _themeLight,
        });
      }
    }
  };

  function generateTheme(_theme) {
    const _themeName = _theme.themeName;
    const _themeImage = _theme.themeImage;
    const _size = {
      base: _theme.size.base,
      fontSize: _theme.size.fontSize,
      lineHeight: _theme.size.lineHeight,
    };
    const _color = {
      primary: createColorScale(_theme.color.primary),
      secondary: createColorScale(_theme.color.secondary),
      tertiary: createColorScale(_theme.color.tertiary),
      accent: createColorScale(_theme.color.accent),
      grayscale: createColorScale(_theme.color.grayscale),
      background: createColorScale(_theme.color.background),
      foreground: createColorScale(_theme.color.foreground),
    };

    return {
      themeName: _themeName,
      themeImage: _themeImage,
      size: _size,
      color: _color,
    };
  }

  const setColorSync = (_isSync, _localStorage = true) => {
    if (_isSync === null) {
      return;
    }
    if (!_isSync) {
      setSync(false);
      setTheme(preferredTheme.light, preferredTheme.dark, dark);
    } else {
      setSync(true);
    }
    if (_localStorage) {
      setLocalStorage('sync', _isSync);
    }
  };

  useEffect(() => {});

  return (
    <ThemeContext.Provider
      value={{
        dark,
        sync,
        themeName,
        themeImage,
        size,
        color,
        setDarkMode,
        setTheme,
        setColorSync,
        preferredTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;

export { ThemeProvider };
