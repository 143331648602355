import * as React from 'react';

const SvgJsMark2 = (props) => (
  <svg
    id='JS-mark2_svg__Layer_1'
    xmlns='https://www.w3.org/2000/svg'
    x={0}
    y={0}
    viewBox='0 0 71 81'
    style={{
      enableBackground: 'new 0 0 71 81',
    }}
    xmlSpace='preserve'
    width='1em'
    height='1em'
    {...props}
  >
    <style>{'.JS-mark2_svg__st0{fill:#fff}'}</style>
    <path
      className='JS-mark2_svg__st0'
      d='m61.2 35 7.4-4.3v-17L46.5 1 25.9 12.9 48 25.6V46l7.4-4.2 4.4 2.5v8.5l-3 1.7V46l-1.4-.8-7.4 4.2v17l20.6-11.9V34.1l-7.4 4.3-4.4-2.6v-8.5l3-1.7v8.5z'
    />
    <path
      className='JS-mark2_svg__st0'
      d='M2.4 26.5v6.8L24.5 46l11.8 6.8v13.6l-2.9 1.7V54.5l-8.8-5.1L2.4 36.7v30.6L24.5 80l20.6-11.9V27.3L23 14.6 2.4 26.5zM36.2 46l-2.9-1.7v-3.4l2.9-1.7V46z'
    />
  </svg>
);

export default SvgJsMark2;
